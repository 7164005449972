import { applyMiddleware, createStore } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { StateType } from 'typesafe-actions';
import { routerMiddleware as createRouterMiddleware } from 'connected-react-router';
import { RootAction } from 'store/actions';
import { composeEnhancers } from 'store/utils';
import { history } from 'store/router/history';
import rootEpic from './epics';
import rootReducer, { RootState } from './reducer';
import { rootDependencies, RootDependencies } from 'store/dependencies';
import { createReduxEnhancer } from '@sentry/react';
import { sentryStateTransformer } from './utils/sentry-state-transformer.util';
import { checkTokenExpirationMiddleware } from 'store/middlewares/check-token-expiration';

const sentryReduxEnhancer = createReduxEnhancer({
  stateTransformer: sentryStateTransformer,
});

export const epicMiddleware = createEpicMiddleware<RootAction, RootAction, RootState, RootDependencies>({
  dependencies: rootDependencies,
});
const routerMiddleware = createRouterMiddleware(history);
const enhancer = composeEnhancers(
  applyMiddleware(checkTokenExpirationMiddleware, epicMiddleware, routerMiddleware),
  sentryReduxEnhancer,
);
const initialState = {};
export const store = createStore(rootReducer, initialState, enhancer);

epicMiddleware.run(rootEpic);

export type Store = StateType<typeof store>;
