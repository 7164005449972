import styled, { css } from 'styled-components';

import { IconCircleWrapper } from 'components/shared/icon-circle-wrapper/icon-cirlce-wrapper';
import { mediaMaxWidth } from '@hqo/react-components-library/dist/device';

export const VisitStatusWrapper = styled.div`
  width: 100%;
  padding-right: 102px;

  ${mediaMaxWidth(css`
    padding: 0 16px 26px 0;
    margin-bottom: 40px;
    border-bottom: 1px solid ${({ theme }): string => theme.colors.greys.sharedMediumGrey};
  `)}
`;

export const VisitStatusTitle = styled.div`
  color: ${({ theme }): string => theme.colors.fontColor};
  line-height: 32px;
  font-weight: bold;
  font-size: 28px;
  margin-left: 16px;
`;

export const VisitStatusTitleWrapper = styled.div`
  display: flex;
  margin-bottom: 16px;
  align-items: center;

  ${mediaMaxWidth(css`
    margin-bottom: 16px;
  `)}
`;

export const VisitStatusDescription = styled.span`
  display: block;
  font-size: 16px;
  color: ${({ theme }): string => theme.colors.fontColor};
  line-height: 20px;
`;

export const PendingWrapper = styled(IconCircleWrapper)`
  color: ${({ theme }): string => theme.colors.admin.orangeAlt};
`;

export const ConfirmedWrapper = styled(IconCircleWrapper)`
  color: ${({ theme }): string => theme.colors.admin.green};
`;

export const CanceledWrapper = styled(IconCircleWrapper)`
  color: ${({ theme }): string => theme.colors.admin.pink};
`;
