import { EscortState } from './types';
import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { fetchEscorts } from './actions';
import { isStandardPaginationResult } from '../utils';

export const initialState: EscortState = {
  data: null,
  error: null,
  loading: false,
};

const loading = createReducer(initialState.loading)
  .handleAction(fetchEscorts.request, () => true)
  .handleAction(fetchEscorts.success, () => false)
  .handleAction(fetchEscorts.failure, () => false);

const error = createReducer(initialState.error)
  .handleAction(fetchEscorts.success, () => null)
  .handleAction(fetchEscorts.failure, (_state, action) => action.payload.error);

const data = createReducer(initialState.data)
  .handleAction(fetchEscorts.success, (_state, action) =>
    isStandardPaginationResult(action.payload) ? action.payload.data.escorts : action.payload.data,
  )
  .handleAction(fetchEscorts.failure, () => null);

export default combineReducers({ loading, error, data });
