import { FormattedMessage } from 'react-intl';
import { MouseEventHandler } from 'react';
import { Tab as TabStyled } from './tabs.styles';
import { VisitUiType } from 'store/visits/types';
import { useRouteMatch } from 'react-router';

interface TabProps {
  type: VisitUiType;
  onClick?: MouseEventHandler;
}
export const Tab = ({ type, onClick }: TabProps): JSX.Element => {
  const { url } = useRouteMatch();

  return (
    <TabStyled
      activeClassName="active"
      to={`${url}/${type}`}
      data-cy={`${type}-visitors-tab`}
      data-testid={`${type}-visitors-tab`}
      onClick={onClick}
    >
      <FormattedMessage id={`labels.${type}`} />
    </TabStyled>
  );
};
