import { ERR_DEFAULT, UserEvents } from 'shared/consts';
import {
  cancelVisit as cancelVisitAction,
  createVisit as createVisitAction,
  fetchAllVisits as fetchAllVisitsAction,
  fetchPastVisits as fetchPastVisitsAction,
  fetchUpcomingVisits as fetchUpcomingVisitsAction,
  fetchVisit as fetchVisitAction,
  fetchVisits as fetchVisitsAction,
  updateVisit as updateVisitAction,
} from './actions';
import { catchError, filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { getCurrentBuildingDateTime, visitCanceled } from './util';
import { mapPaginatedVisits, mapVisit } from 'store/visits/mappers';

import { Epic } from 'redux-observable';
import { RootAction } from 'store/actions';
import { RootDependencies } from 'store/dependencies';
import { RootState } from 'store/reducer';
import { VisitUiType } from './types';
import { getErrorCode } from 'store/utils';
import { isActionOf } from 'typesafe-actions';
import { of } from 'rxjs';
import { push } from 'connected-react-router';
import { track } from '@hqo/web-tracking';

export const fetchVisits: Epic<RootAction, RootAction, RootState, RootDependencies> = (action$) =>
  action$.pipe(
    filter(isActionOf(fetchVisitsAction)),
    map((action) => {
      const { type, ...actionParams } = action.payload;

      const filterParam = getCurrentBuildingDateTime(type);
      const payload = { ...actionParams, ...filterParam };

      switch (type) {
        case VisitUiType.PAST:
          return fetchPastVisitsAction.request(payload);
        case VisitUiType.UPCOMING:
          return fetchUpcomingVisitsAction.request(payload);
        case VisitUiType.ALL:
        default:
          return fetchAllVisitsAction.request(actionParams);
      }
    }),
  );

export const fetchUpcomingVisits: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(fetchUpcomingVisitsAction.request)),
    withLatestFrom(state$),
    mergeMap(([{ payload }, state]) => {
      return apiClient(state)
        .fetchVisits(payload)
        .pipe(
          map(({ response }) =>
            fetchUpcomingVisitsAction.success({
              params: payload,
              result: mapPaginatedVisits(response, state.user.user),
            }),
          ),
          catchError((error: Error) =>
            of(
              fetchUpcomingVisitsAction.failure({
                params: payload,
                error,
                errorCode: getErrorCode(error),
              }),
            ),
          ),
        );
    }),
  );

export const fetchPastVisits: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(fetchPastVisitsAction.request)),
    withLatestFrom(state$),
    mergeMap(([{ payload }, state]) => {
      return apiClient(state)
        .fetchVisits(payload)
        .pipe(
          map(({ response }) =>
            fetchPastVisitsAction.success({
              params: payload,
              result: mapPaginatedVisits(response, state.user.user),
            }),
          ),
          catchError((error: Error) =>
            of(
              fetchPastVisitsAction.failure({
                params: payload,
                error,
                errorCode: getErrorCode(error),
              }),
            ),
          ),
        );
    }),
  );

export const fetchAllVisits: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(fetchAllVisitsAction.request)),
    withLatestFrom(state$),
    mergeMap(([{ payload }, state]) => {
      return apiClient(state)
        .fetchVisits({ ...payload })
        .pipe(
          map(({ response }) =>
            fetchAllVisitsAction.success({
              params: payload,
              result: mapPaginatedVisits(response, state.user.user),
            }),
          ),
          catchError((error: Error) =>
            of(
              fetchAllVisitsAction.failure({
                params: payload,
                error,
                errorCode: getErrorCode(error),
              }),
            ),
          ),
        );
    }),
  );

export const fetchVisit: Epic<RootAction, RootAction, RootState, RootDependencies> = (action$, state$, { apiClient }) =>
  action$.pipe(
    filter(isActionOf(fetchVisitAction.request)),
    withLatestFrom(state$),
    mergeMap(([{ payload }, state]) => {
      return apiClient(state)
        .fetchVisit(payload)
        .pipe(
          map(({ response }) =>
            fetchVisitAction.success({
              params: payload,
              result: response && mapVisit(response, state.user.user),
            }),
          ),
          catchError((error: Error) =>
            of(
              fetchVisitAction.failure({
                params: payload,
                error,
                errorCode: getErrorCode(error),
              }),
            ),
          ),
        );
    }),
  );

export const cancelVisit: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(cancelVisitAction.request)),
    withLatestFrom(state$),
    mergeMap(([{ payload }, state]) => {
      return apiClient(state)
        .cancelVisit(payload)
        .pipe(
          map(({ response }) => {
            if (response.canceled) {
              visitCanceled(state?.uiMetadata?.data?.integration_name);
              return cancelVisitAction.success(payload);
            }

            return cancelVisitAction.failure({
              params: payload,
              error: new Error('Error canceling visit'),
              errorCode: ERR_DEFAULT,
            });
          }),
          catchError((error: Error) =>
            of(cancelVisitAction.failure({ params: payload, error, errorCode: getErrorCode(error) })),
          ),
        );
    }),
  );

export const cancelVisitRedirect: Epic<RootAction, RootAction, RootState, RootDependencies> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(cancelVisitAction.success)),
    withLatestFrom(state$),
    map(([{ payload }, state]) => {
      const currentUrl: string = state.router.location.pathname;
      const newUrl = currentUrl.replace(new RegExp(`/${payload.visitId}`), '');

      return push(newUrl);
    }),
  );

export const createVisit: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(createVisitAction.request)),
    withLatestFrom(state$),
    mergeMap(([{ payload }, state]) => {
      return apiClient(state)
        .createVisit(payload)
        .pipe(
          map(({ response }) => {
            track(
              UserEvents.VISIT_SUBMITTED,
              {
                type: 'action',
                service_provider: state.uiMetadata?.data?.integration_name ?? 'UNKNOWN',
                visitors_added: payload.visitDto.visitors.length,
              },
              { sendToPendo: true, sendToHqoTracking: true },
            );
            return createVisitAction.success(mapVisit(response, state.user.user));
          }),
          catchError((error: Error) => of(createVisitAction.failure({ error, errorCode: getErrorCode(error) }))),
        );
    }),
  );

export const createVisitRedirect: Epic<RootAction, RootAction, RootState, RootDependencies> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(createVisitAction.success)),
    withLatestFrom(state$),
    map(([{ payload }, state]) => {
      const currentUrl: string = state.router.location.pathname;
      const newUrl = currentUrl.replace(/\/new.*/, `/${VisitUiType.UPCOMING}/${payload.id}`);

      return push(newUrl);
    }),
  );

export const updateVisit: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(updateVisitAction.request)),
    withLatestFrom(state$),
    mergeMap(([{ payload }, state]) => {
      return apiClient(state)
        .updateVisit(payload)
        .pipe(
          map(({ response }) => {
            return updateVisitAction.success({
              params: payload.params,
              result: mapVisit(response, state.user.user),
            });
          }),
          catchError((error: Error) =>
            of(updateVisitAction.failure({ params: payload.params, error, errorCode: getErrorCode(error) })),
          ),
        );
    }),
  );

export const updateVisitRedirect: Epic<RootAction, RootAction, RootState, RootDependencies> = (action$) =>
  action$.pipe(
    filter(isActionOf(updateVisitAction.success)),
    map(() => {
      const currentUrl: string = window.location.pathname;
      const newUrl = currentUrl.replace(/\/edit.*/, '');

      return push(newUrl);
    }),
  );
