import { IconBackground, IconWrapper } from './icon-cirlce-wrapper.styles';

import { IconContentProps } from '@hqo/react-components-library/dist/icons';
import React from 'react';

interface IconCircleWrapperProps extends IconContentProps {
  className?: string;
  children: React.FC<IconContentProps>;
}

export const IconCircleWrapper = ({
  children: Icon,
  size,
  className,
  ...iconProps
}: IconCircleWrapperProps): JSX.Element => (
  <IconWrapper className={className} size={size}>
    <IconBackground />
    <Icon className="icon" size={size} {...iconProps} />
  </IconWrapper>
);
