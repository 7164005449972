import { SelectItem } from '@hqo/react-components-library/dist/select-item';
import React from 'react';

import { AltBuilding } from '../../../store/alt-buildings/types';
import { StyledSelect } from './styles';
import { useSetSelectedAltBuilding } from '../../../hooks/use-alt-buildings.hook';

interface SwitchAltBuildingsModalProps {
  altBuildings: Array<AltBuilding>;
  setSelectedAltBuilding: React.Dispatch<React.SetStateAction<AltBuilding>>;
  selectedAltBuilding: AltBuilding;
  currentAltBuilding?: AltBuilding | null;
}

export const AltBuildingModalSelect = ({
  altBuildings,
  setSelectedAltBuilding,
  selectedAltBuilding,
  currentAltBuilding,
}: SwitchAltBuildingsModalProps): JSX.Element => {
  const handleSelectAltBuilding = useSetSelectedAltBuilding(setSelectedAltBuilding);

  return (
    <StyledSelect
      onChange={handleSelectAltBuilding}
      defaultValue={currentAltBuilding?.alt_building_uuid || ''}
      value={selectedAltBuilding?.alt_building_uuid}
      selectedValueLabel={selectedAltBuilding?.name}
      data-testid="alt-building-modal-body"
      data-cy="alt-building"
    >
      {altBuildings.map((altBuilding: AltBuilding) => (
        <SelectItem
          key={altBuilding.alt_building_uuid}
          renderedValue={altBuilding.name}
          value={altBuilding.alt_building_uuid}
          data-cy={`alt-buildings-item-${altBuilding.alt_building_uuid}`}
        />
      ))}
    </StyledSelect>
  );
};
