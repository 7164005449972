import { Building, FloorData, VisitTypeData } from './types';
import { building, buildingState } from 'store/building/selectors';
import { useDispatch, useSelector } from 'react-redux';

import { OwnerIdParam } from 'shared/types';
import { fetchBuilding } from 'store/building/actions';
import { useEffect } from 'react';
import { useParams } from 'react-router';

export const useFetchBuilding = (): boolean => {
  const { ownerId } = useParams<OwnerIdParam>();
  const dispatch = useDispatch();
  const { loading } = useSelector(buildingState);

  useEffect(() => {
    dispatch(fetchBuilding.request({ ownerId }));
  }, [dispatch, ownerId]);

  return loading;
};

export const useBuildingState = (): Building | null => useSelector(building);

export const useBuildingData = (): {
  floors: FloorData[] | null;
  visitTypes: VisitTypeData[] | null;
} => {
  const data = useBuildingState();

  return {
    floors: data?.floors ?? null,
    visitTypes: data?.visit_types ?? null,
  };
};
