import { NewVisitorLinkStyled, StyledButton } from './new-visitor-button.styles';

import { useIntl } from 'react-intl';

interface NewVisitorButtonProps {
  className?: string;
}

export const NewVisitorButton = ({ className }: NewVisitorButtonProps): JSX.Element => {
  const { formatMessage } = useIntl();

  return (
    <NewVisitorLinkStyled className={className}>
      <StyledButton text={formatMessage({ id: 'buttons.newVisitor' })} iconDirection="left" />
    </NewVisitorLinkStyled>
  );
};
