import { EmptyListPlaceholder } from '../empty-list-placeholder';
import { InfiniteLoadingList } from 'components/visitors-list/infinite-loading-list';
import { VisitUiType } from 'store/visits/types';
import { getPaginatedVisitsState } from 'store/visits/selectors';
import { useSelector } from 'react-redux';

interface ListProps {
  type: VisitUiType;
}

export const List = ({ type }: ListProps): JSX.Element => {
  const { total } = useSelector(getPaginatedVisitsState(type));

  if (total === 0) {
    return <EmptyListPlaceholder type={type} />;
  }

  return <InfiniteLoadingList type={type} total={total} />;
};
