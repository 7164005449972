import { ERROR_PATH, ROOT_PATH, VISITOR_REGISTRATION_PATH } from 'shared/consts';
import { Route, Switch } from 'react-router-dom';

import { DefaultRedirect } from './default-redirect';
import { ErrorPage } from 'pages/error';
import { PrivateRoute } from 'components/root/private-route';
import React from 'react';
import { VisitorRegistrationRoot } from 'components/visitor-registration-root';
import { VisitorRegistrationRoute } from 'components/root/router/visitor-registration-route';

export const Router: React.FC = (): JSX.Element => (
  <Switch>
    <PrivateRoute path={ROOT_PATH} component={VisitorRegistrationRoute} />
    <Route path={VISITOR_REGISTRATION_PATH} component={VisitorRegistrationRoot} exact />
    <Route path="/" component={VisitorRegistrationRoot} exact />
    <Route path={ERROR_PATH} component={ErrorPage} />
    <Route component={DefaultRedirect} />
  </Switch>
);
