import styled, { css } from 'styled-components';

import { NewVisitorButton } from 'components/shared/new-visitor-button';
import { NewVisitorLink } from 'components/shared/new-visitor-link';
import { Plus } from '@hqo/react-components-library/dist/icons';
import { mediaMaxWidth } from '@hqo/react-components-library/dist/device';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 60px;
  width: 100%;

  ${mediaMaxWidth(css`
    padding: 0 24px 0 16px;
  `)}
`;

export const NewVisitorMobileButton = styled.button`
  background-color: ${({ theme }) => theme.colors.universal.transparent};
  border: 0 none;
  width: 24px;
  height: 24px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NewVisitorLinkStyled = styled(NewVisitorLink)`
  ${mediaMaxWidth(css`
    display: block;
  `)}
`;

export const NewVisitorButtonStyled = styled(NewVisitorButton)`
  display: block;

  ${mediaMaxWidth(css`
    display: none;
  `)}
`;

export const MobilePlusIcon = styled(Plus)`
  width: 18px;
  height: 18px;
  color: ${({ theme }) => theme.colors.fontColor};
`;
