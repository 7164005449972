import { AjaxResponse, ajax } from 'rxjs/ajax';
import {
  CancelVisitResult,
  CreateVisitParams,
  FetchVisitsParams,
  PaginationVisits,
  UpdateVisitActionParams,
  Visit,
  VisitParams,
} from 'store/visits/types';
import { OwnerIdParam, OwnerParams } from 'shared/types';

import { Observable } from 'rxjs';
import { VERTICAL_NAME } from 'shared/consts';
import { stringify } from 'qs';
import { User } from 'store/user/types';
import { UiMetadata } from 'store/ui-metadata/types';
import { Building } from 'store/building/types';
import { Escorts } from 'store/escorts/types';
import { TenantBuildingDto } from 'store/tenant-building/types';
import { BuildingTheme } from 'store/theme/types';
import { Hosts } from 'store/hosts/types';
import { AltBuilding } from 'store/alt-buildings/types';
import { VisitWithOptionalHost } from 'store/visits/mappers';
import { FetchVisitorsParams, FetchVisitorsResponse } from 'store/api-client/api-client.types';

const stringifyQuery = (params: unknown): string =>
  stringify(params, { skipNulls: true, arrayFormat: 'brackets', addQueryPrefix: true });
export class ApiClient {
  constructor(
    private baseUrl: string,
    private authToken?: string | null,
    private altBuildingUuid?: string | null,
    private buildingUuid?: string | null,
  ) {}

  private get DefaultHeaders(): Record<string, string> {
    return {
      'Content-Type': 'application/json',
      ...(this.authToken ? { Authorization: this.authToken } : {}),
      ...(this.altBuildingUuid ? { 'alt-building-uuid': this.altBuildingUuid } : {}),
      ...(this.buildingUuid ? { 'hqo-building-uuid': this.buildingUuid } : {}),
    };
  }

  getCurrentUser(): Observable<AjaxResponse<User>> {
    return ajax.get(`${this.baseUrl}/api/auth/current`, this.DefaultHeaders);
  }

  fetchUiMetadata({ ownerType, ownerId }: OwnerParams): Observable<AjaxResponse<UiMetadata>> {
    return ajax.get(
      `${this.baseUrl}/api/verticals/${VERTICAL_NAME}/${ownerType.toUpperCase()}/${ownerId}/ui-metadata`,
      this.DefaultHeaders,
    );
  }

  fetchVisitors({ query, buildingId }: FetchVisitorsParams): Observable<AjaxResponse<FetchVisitorsResponse>> {
    const queryParams = stringifyQuery({
      query,
    });

    return ajax.get(
      `${this.baseUrl}/api/visitor-registration/v2/locations/${buildingId}/visitors${queryParams}`,
      this.DefaultHeaders,
    );
  }

  fetchVisits({ buildingId, ...queryParams }: FetchVisitsParams): Observable<AjaxResponse<PaginationVisits>> {
    return ajax.get(
      `${this.baseUrl}/api/visitor-registration/v2/locations/${buildingId}/visits?${stringify(queryParams)}`,
      this.DefaultHeaders,
    );
  }

  fetchBuilding({ ownerId }: OwnerIdParam): Observable<AjaxResponse<Building>> {
    return ajax.get(`${this.baseUrl}/api/visitor-registration/v2/locations/${ownerId}`, this.DefaultHeaders);
  }

  fetchEscorts({ ownerId }: OwnerIdParam): Observable<AjaxResponse<Escorts>> {
    return ajax.get(`${this.baseUrl}/api/visitor-registration/v2/locations/${ownerId}/escorts`, this.DefaultHeaders);
  }

  fetchVisit({ buildingId, visitId }: VisitParams): Observable<AjaxResponse<VisitWithOptionalHost>> {
    return ajax.get(
      `${this.baseUrl}/api/visitor-registration/v2/locations/${buildingId}/visits/${visitId}`,
      this.DefaultHeaders,
    );
  }

  cancelVisit({ buildingId, visitId }: VisitParams): Observable<AjaxResponse<CancelVisitResult>> {
    return ajax.delete(
      `${this.baseUrl}/api/visitor-registration/v2/locations/${buildingId}/visits/${visitId}`,
      this.DefaultHeaders,
    );
  }

  createVisit({ buildingId, visitDto }: CreateVisitParams): Observable<AjaxResponse<VisitWithOptionalHost>> {
    return ajax.post(
      `${this.baseUrl}/api/visitor-registration/v2/locations/${buildingId}/visits`,
      visitDto,
      this.DefaultHeaders,
    );
  }

  updateVisit({ params, payload }: UpdateVisitActionParams): Observable<AjaxResponse<Visit>> {
    return ajax.patch(
      `${this.baseUrl}/api/visitor-registration/v2/locations/${params.buildingId}/visits/${params.visitId}`,
      payload,
      this.DefaultHeaders,
    );
  }

  fetchTenantBuilding(buildingUuid: string): Observable<AjaxResponse<TenantBuildingDto>> {
    return ajax.get(`${this.baseUrl}/api/tenant/v1/buildings/${buildingUuid}`, this.DefaultHeaders);
  }

  getBuildingTheme(buildingUuid: string): Observable<AjaxResponse<BuildingTheme>> {
    return ajax.get(`${this.baseUrl}/api/buildings/${buildingUuid}/theme`, this.DefaultHeaders);
  }

  fetchHosts({ ownerId }: OwnerIdParam): Observable<AjaxResponse<Hosts>> {
    return ajax.get(`${this.baseUrl}/api/visitor-registration/v2/locations/${ownerId}/hosts`, this.DefaultHeaders);
  }

  getAltBuildings(buildingUuid: string): Observable<AjaxResponse<AltBuilding[]>> {
    return ajax.get(
      `${this.baseUrl}/api/tenant/v1/buildings/alt_buildings/${buildingUuid}/?vertical_name=${VERTICAL_NAME}`,
      this.DefaultHeaders,
    );
  }
}
