import { PaginationVisits, Visit } from 'store/visits/types';

import { PaginationResult } from 'shared/types';
import { User } from 'store/user/types';
import { isStandardPaginationResult } from 'store/utils';

export type VisitWithOptionalHost = Omit<Visit, 'host'> & Pick<Partial<Visit>, 'host'>;

export const mapVisit = (visit: VisitWithOptionalHost, currentUser: User | null): Visit => {
  return {
    ...visit,
    host: visit.host ?? {
      first_name: currentUser?.first_name ?? '',
      last_name: currentUser?.last_name ?? '',
      email: currentUser?.email ?? '',
    },
  };
};

export const mapPaginatedVisits = (
  paginatedVisits: PaginationVisits,
  currentUser: User | null,
): PaginationResult<Visit> => {
  return isStandardPaginationResult(paginatedVisits)
    ? {
        ...paginatedVisits,
        data: paginatedVisits.data.visits.map((visit) => mapVisit(visit, currentUser)),
      }
    : {
        ...paginatedVisits,
        data: paginatedVisits.data.map((visit) => mapVisit(visit, currentUser)),
      };
};
