import { useIntl } from 'react-intl';
import { useNavigate } from 'hooks/use-navigate-handler.hook';
import { ROOT_PATH, VISITS_SUB_PATH } from 'shared/consts';
import { Pending } from '@hqo/react-components-library/dist/icons';
import { Modal } from 'components/shared/modal';
import {
  VisitModalPendingWrapper,
  VisitPendingTitleWrapper,
  PendingNotificationButton,
} from './pending-visit-page.styles';
import {
  VisitStatusTitle,
  VisitStatusDescription,
  PendingWrapper,
} from 'components/visit-modal/visit-status/visit-status.styles';

export const PendingVisitPage = (): JSX.Element => {
  const { formatMessage } = useIntl();
  const handleClose = useNavigate(`${ROOT_PATH}/${VISITS_SUB_PATH}`);

  return (
    <Modal onClose={handleClose} variant="wide">
      <VisitModalPendingWrapper>
        <PendingWrapper className="visit-pending">{Pending}</PendingWrapper>
        <VisitPendingTitleWrapper>
          <VisitStatusTitle data-cy="visit-pending-title">
            {formatMessage({ id: 'labels.visit.status.pending' })}
          </VisitStatusTitle>
        </VisitPendingTitleWrapper>
        <VisitStatusDescription data-cy="visit-pending-description">
          {formatMessage({ id: 'labels.visit.status.pendingDescription' })}
        </VisitStatusDescription>
        <PendingNotificationButton
          className="button"
          text={formatMessage({ id: 'buttons.confirm' })}
          onClick={handleClose}
          dataCy="visit-pending-button"
          data-testid="visit-pending-button"
        />
      </VisitModalPendingWrapper>
    </Modal>
  );
};
