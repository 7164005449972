import styled, { css } from 'styled-components';

import { NewVisitorButton } from 'components/shared/new-visitor-button';
import { mediaMaxWidth } from '@hqo/react-components-library/dist/device';

export const Wrapper = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.greys.sharedDarkGrey};

  width: 100%;
  max-width: 280px;
  height: 100%;
`;

export const Title = styled.h1`
  margin: 20px 0;
  font-weight: bold;
  font-size: 20px;
`;

export const Description = styled.p`
  margin: 0;
  text-align: center;
  font-size: 14px;
`;

export const NewVisitorButtonStyled = styled(NewVisitorButton)`
  display: none;

  ${mediaMaxWidth(css`
    margin-top: 22px;
    display: block;
    width: 276px;
    height: 50px;
  `)}
`;
