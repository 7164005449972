import moment, { Moment } from 'moment-timezone';

import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useLocale } from 'shared/hooks/use-locale';
import { useTimeZone } from 'shared/hooks/use-timezone';

export type DateTime = string | Date | Moment;
export type DateTimeFormatter = (dateTime: DateTime | undefined) => string;
type DateConverter = (dateTime?: DateTime) => Moment | null;

interface DateTimeFormatters {
  formatNumericDate: DateTimeFormatter;
  formatDate: DateTimeFormatter;
  formatTime: DateTimeFormatter;
}

interface DateTimeFormats {
  timeFormat?: Intl.DateTimeFormatOptions;
  dateFormat?: Intl.DateTimeFormatOptions;
}

export const useDateConverter = (): DateConverter => {
  const timeZone = useTimeZone();

  return useCallback(
    (dateTime?: DateTime): Moment | null => {
      return dateTime ? moment(dateTime).tz(timeZone) : null;
    },
    [timeZone],
  );
};

export const useDateFormatter = (format?: Intl.DateTimeFormatOptions): DateTimeFormatter => {
  const timeZone = useTimeZone();
  const locale = useLocale();

  return useCallback(
    (dateTime?: DateTime): string => {
      if (!dateTime) {
        return '';
      }

      const formatter = new Intl.DateTimeFormat(locale, { timeZone, ...format });

      if (dateTime instanceof Date || typeof dateTime === 'string') {
        return formatter.format(new Date(dateTime));
      }

      // dateTime might be moment
      return formatter.format(dateTime.toDate());
    },
    [format, locale, timeZone],
  );
};

export const useTimeFormatter = (format = { timeStyle: 'short' } as Intl.DateTimeFormatOptions): DateTimeFormatter => {
  return useDateFormatter(format);
};

export const useDateTimeFormatters = (formats?: DateTimeFormats): DateTimeFormatters => {
  const formatNumericDate = useDateFormatter();

  const formatDate = useDateFormatter(formats?.dateFormat);
  const formatTime = useTimeFormatter(formats?.timeFormat);

  return {
    formatDate,
    formatNumericDate,
    formatTime,
  };
};

export const isCertainTime = (dateTime: Moment | null, hours: number, minutes: number): boolean => {
  return dateTime?.hour() === hours && dateTime?.minutes() === minutes;
};

export const useVisitTime = (
  arrivalDate?: Date,
  departureDate?: Date,
  arrivalTime?: string,
  departureTime?: string,
): string[] => {
  const convertDate = useDateConverter();
  const { formatMessage } = useIntl();
  const arrivalMoment = convertDate(arrivalDate);
  const departureMoment = convertDate(departureDate);

  const timeVisit = isCertainTime(departureMoment, 23, 59)
    ? [`${formatMessage({ id: 'labels.from' })} ${arrivalTime}`]
    : [arrivalTime, departureTime];

  return isCertainTime(arrivalMoment, 0, 0) && isCertainTime(departureMoment, 23, 59)
    ? [formatMessage({ id: 'labels.allDayVisit' })]
    : timeVisit;
};
