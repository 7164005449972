import { Description, NewVisitorButtonStyled, Title, Wrapper } from './empty-list-placeholder.styles';

import { AddVisitorIcon } from './add-visitor-icon';
import { FormattedMessage } from 'react-intl';
import { VisitUiType } from 'store/visits/types';

interface EmptyListPlaceholderProps {
  type: VisitUiType;
}

export const EmptyListPlaceholder = ({ type }: EmptyListPlaceholderProps): JSX.Element => (
  <Wrapper data-cy="empty-list-placeholder" data-testid="empty-list-placeholder">
    <AddVisitorIcon />
    <Title>
      <FormattedMessage id="labels.noVisitors" />
    </Title>
    <Description>
      <FormattedMessage id={`labels.noVisitorsPlaceholder.${type}`} />
    </Description>
    <Description>
      <FormattedMessage id="labels.pleaseRegisterANewVisitor" />
    </Description>
    <NewVisitorButtonStyled />
  </Wrapper>
);
