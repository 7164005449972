import { StyledModal } from 'components/shared/modal/modal.styles';
import React from 'react';

interface ModalContentProps {
  children: React.ReactNode;
  maxWidth: number | undefined;
  onClose: VoidFunction | undefined;
  variant: 'small' | 'normal' | 'wide';
  withExitButton: boolean;
}

export const ModalContent = ({
  children,
  maxWidth,
  onClose,
  variant,
  withExitButton,
}: ModalContentProps): JSX.Element => {
  return (
    <StyledModal
      opened
      maxWidth={maxWidth}
      onClose={onClose}
      dataTestId="modal-window"
      data-cy="modal-window"
      variant={variant}
      withExitButton={withExitButton}
    >
      {children}
    </StyledModal>
  );
};
