import { Escorts } from './types';
import { FailureActionPayload } from 'store/errors/types';
import { OwnerIdParam } from 'shared/types';
import { createAsyncAction } from 'typesafe-actions';

export const fetchEscorts = createAsyncAction(
  'escorts/FETCH_REQUEST',
  'escorts/FETCH_SUCCESS',
  'escorts/FETCH_FAILURE',
)<OwnerIdParam, Escorts, FailureActionPayload>();
