import { compose } from 'redux';
import { AjaxError } from 'rxjs/ajax';
import { ERR_DEFAULT } from 'shared/consts';
import { PaginationResult } from 'shared/types';
import { ResponseKeys, StandardPaginationResult } from 'shared/types/standard-pagination-result';

const reduxDevToolsExtensionCompose =
  process.env.NODE_ENV === 'development' && window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
export const composeEnhancers = reduxDevToolsExtensionCompose || compose;

export function getErrorCode(error: Error): string {
  if (error instanceof AjaxError) {
    return error.response?.data?.code ?? error.response?.errorCode ?? ERR_DEFAULT;
  }

  return ERR_DEFAULT;
}

export function isStandardPaginationResult<T, K extends ResponseKeys>(
  result: PaginationResult<T> | StandardPaginationResult<T, K>,
): result is StandardPaginationResult<T, K> {
  return !Array.isArray(result.data);
}
