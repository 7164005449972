import { Step } from 'components/visit-form/types';
import { useIsEditMode } from 'components/visit-form/context/is-editing.hook';
import { useNavigate } from 'hooks/use-navigate-handler.hook';
import { ROOT_PATH, VISITS_SUB_PATH } from 'shared/consts';
import { ErrorCode } from 'shared/types/error-codes';

export const useErrorHandlers = (): Partial<Record<ErrorCode, VoidFunction>> => {
  const isEditingMode = useIsEditMode();
  const visitorPageUrl = `${ROOT_PATH}/${VISITS_SUB_PATH}/${isEditingMode ? 'edit' : 'new'}/${Step.Visitor}`;
  const redirectToVisitorPage = useNavigate(visitorPageUrl);

  return {
    [ErrorCode.DUPLICATE_PROFILE]: redirectToVisitorPage,
  };
};
