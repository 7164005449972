import { HostState } from './types';
import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { fetchHosts, saveHostSearchValue } from './actions';
import { isStandardPaginationResult } from '../utils';

export const initialState: HostState = {
  data: null,
  error: null,
  loading: false,
  fieldValue: '',
};

const loading = createReducer(initialState.loading)
  .handleAction(fetchHosts.request, () => true)
  .handleAction(fetchHosts.success, () => false)
  .handleAction(fetchHosts.failure, () => false);

const error = createReducer(initialState.error)
  .handleAction(fetchHosts.success, () => null)
  .handleAction(fetchHosts.failure, (_state, action) => action.payload.error);

const data = createReducer(initialState.data)
  .handleAction(fetchHosts.success, (_state, action) =>
    isStandardPaginationResult(action.payload) ? action.payload.data.hosts : action.payload.data,
  )
  .handleAction(fetchHosts.failure, () => null);

const fieldValue = createReducer(initialState.fieldValue).handleAction(
  saveHostSearchValue,
  (_state, action) => action.payload,
);

export default combineReducers({ loading, error, data, fieldValue });
