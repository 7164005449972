import { MessageFormatElement, useIntl } from 'react-intl';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AjaxError } from 'rxjs/ajax';
import { ERR_DEFAULT } from '../../shared/consts/error-codes';
import { ErrorNotification } from './error-notification';
import { ErrorNotification as ErrorNotificationData } from 'store/errors/types';
import { UserEvents } from 'shared/consts';
import { Wrapper } from './error-page.styles';
import { getCriticalErrorNotifications } from '../../store/errors/selectors';
import { goBack } from 'connected-react-router';
import { hideCriticalErrorNotifications } from 'store/errors/actions';
import { track } from '@hqo/web-tracking';
import { useHistory } from 'react-router';

const INTERNAL_SERVER_ERROR_CODE = 500;

const getErrorStatus = (
  hasErrorNotifications: boolean,
  criticalErrors: ErrorNotificationData[],
  messages: Record<string, string> | Record<string, MessageFormatElement[]>,
) => {
  let errorCode = ERR_DEFAULT;
  let showButton = true;
  const criticalError = criticalErrors[0];
  if (
    hasErrorNotifications &&
    messages[`notifications.criticalErrors.${criticalError?.errorCode?.toLowerCase()}.title`] !== undefined
  ) {
    errorCode = criticalError?.errorCode?.toLowerCase();
  }
  if (criticalError?.error instanceof AjaxError && criticalError.error.status === INTERNAL_SERVER_ERROR_CODE) {
    showButton = false;
  }
  return { showButton, errorCode };
};

export const ErrorPage = (): JSX.Element => {
  const { messages } = useIntl();
  const { hasErrorNotifications, criticalErrors } = useSelector(getCriticalErrorNotifications);
  const dispatch = useDispatch();
  const { action } = useHistory();
  const handleClick = useCallback(() => {
    if (action !== 'POP') {
      dispatch(goBack());
      dispatch(hideCriticalErrorNotifications());
    }
  }, [action, dispatch]);
  const { showButton, errorCode } = getErrorStatus(hasErrorNotifications, criticalErrors, messages);
  useEffect(() => {
    track(
      UserEvents.VISIT_ERROR_IMPRESSION,
      { type: 'impression', error_details: errorCode },
      { sendToPendo: true, sendToHqoTracking: true },
    );
  }, [errorCode]);
  return (
    <Wrapper>
      <ErrorNotification errorCode={errorCode} handleClick={handleClick} showButton={showButton} />
    </Wrapper>
  );
};
