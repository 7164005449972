import { useIsItemLoadedCallback, useItemsFetcher } from 'components/visitors-list/infinite-loading-list/hooks';

import { InfiniteLoader } from '@hqo/react-components-library/dist/molecules/infinite-loader';
import { VisitItem } from './visit-item';
import { VisitUiType } from 'store/visits/types';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/device';

interface InfiniteLoadingListProps {
  type: VisitUiType;
  total: number | null;
}

export const InfiniteLoadingList = ({ type, total }: InfiniteLoadingListProps): JSX.Element => {
  const isSmallScreen = useIsSmallViewportWidth();
  const handleLoadMoreItems = useItemsFetcher(type);
  const isItemLoaded = useIsItemLoadedCallback(type);

  return (
    <InfiniteLoader
      isItemLoaded={isItemLoaded}
      itemCount={total ?? 3}
      itemSize={isSmallScreen ? 120 : 80}
      loadMoreItems={handleLoadMoreItems}
      threshold={15}
      minimumBatchSize={10}
      overscanCount={5}
    >
      {({ index, style }) => <VisitItem index={index} style={style} type={type} />}
    </InfiniteLoader>
  );
};
