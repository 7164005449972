import { BuildingState } from './types';
import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { fetchBuilding } from 'store/building/actions';

export const initialState: BuildingState = {
  data: null,
  error: null,
  loading: false,
};

const loading = createReducer(initialState.loading)
  .handleAction(fetchBuilding.request, () => true)
  .handleAction(fetchBuilding.success, () => false)
  .handleAction(fetchBuilding.failure, () => false);

const error = createReducer(initialState.error)
  .handleAction(fetchBuilding.success, () => null)
  .handleAction(fetchBuilding.failure, (_state, action) => action.payload.error);

const data = createReducer(initialState.data)
  .handleAction(fetchBuilding.success, (_state, action) => action.payload)
  .handleAction(fetchBuilding.failure, () => null);

export default combineReducers({ loading, error, data });
