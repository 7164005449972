import {
  CreateVisitParams,
  FetchSpecificVisitsActionParams,
  FetchVisitResponse,
  FetchVisitsActionParams,
  FetchVisitsError,
  FetchVisitsResponse,
  UpdateVisitActionParams,
  UpdateVisitResponse,
  Visit,
  VisitError,
  VisitParams,
  VisitUiType,
} from 'store/visits/types';
import { createAction, createAsyncAction } from 'typesafe-actions';

import { FailureActionPayload } from 'store/errors/types';

export const fetchVisits = createAction('visits/FETCH_REQUEST')<FetchVisitsActionParams & { type: VisitUiType }>();

export const fetchUpcomingVisits = createAsyncAction(
  'visits/FETCH_UPCOMING_REQUEST',
  'visits/FETCH_UPCOMING_SUCCESS',
  'visits/FETCH_UPCOMING_FAILURE',
)<FetchSpecificVisitsActionParams, FetchVisitsResponse, FetchVisitsError>();

export const fetchPastVisits = createAsyncAction(
  'visits/FETCH_PAST_REQUEST',
  'visits/FETCH_PAST_SUCCESS',
  'visits/FETCH_PAST_FAILURE',
)<FetchSpecificVisitsActionParams, FetchVisitsResponse, FetchVisitsError>();

export const fetchAllVisits = createAsyncAction(
  'visits/FETCH_ALL_REQUEST',
  'visits/FETCH_ALL_SUCCESS',
  'visits/FETCH_ALL_FAILURE',
)<FetchSpecificVisitsActionParams, FetchVisitsResponse, FetchVisitsError>();

export const fetchVisit = createAsyncAction(
  'visits/FETCH_ONE_REQUEST',
  'visits/FETCH_ONE_SUCCESS',
  'visits/FETCH_ONE_FAILURE',
)<VisitParams, FetchVisitResponse, VisitError>();

export const cancelVisit = createAsyncAction('visits/CANCEL_REQUEST', 'visits/CANCEL_SUCCESS', 'visits/CANCEL_FAILURE')<
  VisitParams,
  VisitParams,
  VisitError
>();

export const createVisit = createAsyncAction('visits/CREATE_REQUEST', 'visits/CREATE_SUCCESS', 'visits/CREATE_FAILURE')<
  CreateVisitParams,
  Visit,
  FailureActionPayload
>();

export const updateVisit = createAsyncAction('visits/UPDATE_REQUEST', 'visits/UPDATE_SUCCESS', 'visits/UPDATE_FAILURE')<
  UpdateVisitActionParams,
  UpdateVisitResponse,
  VisitError
>();

export const initializeVisitorRegistration = createAction('INITIALIZE_VISITOR_REGISTRATION')<void>();
