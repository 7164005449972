import {
  MobilePlusIcon,
  NewVisitorButtonStyled,
  NewVisitorLinkStyled,
  NewVisitorMobileButton,
  Wrapper,
} from './header.styles';
import { PageTitle } from '@hqo/react-components-library/dist/page-header';
import { useThemeFont } from 'hooks/use-theme-font.hook';

import { FormattedMessage } from 'react-intl';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/device';
import { FontLocation } from 'shared/consts';

export const Header = (): JSX.Element => {
  const isSmallScreen = useIsSmallViewportWidth();
  const themeFontTitle = useThemeFont(FontLocation.TITLE);

  return (
    <Wrapper>
      <PageTitle font={themeFontTitle}>
        <FormattedMessage id="labels.visitorRegistration" />
      </PageTitle>
      {isSmallScreen && (
        <NewVisitorLinkStyled>
          <NewVisitorMobileButton>
            <MobilePlusIcon variant="primary" />
          </NewVisitorMobileButton>
        </NewVisitorLinkStyled>
      )}
      <NewVisitorButtonStyled />
    </Wrapper>
  );
};
