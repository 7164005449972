import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { ErrorNotification } from '@hqo/react-components-library/dist/molecules/errorNotification';
import { track } from '@hqo/web-tracking';
import { UserEvents } from 'shared/consts';
import { ErrorCode } from 'shared/types/error-codes';
import { ErrorNotification as ErrorNotificationData } from 'store/errors/types';
import { getErrorNotifications } from 'store/errors/selectors';
import { hideErrorNotifications } from 'store/errors/actions';
import { Modal } from 'components/shared/modal';
import { useErrorHandlers } from './hooks';

const errorCodesSet = new Set<ErrorCode>(Object.values(ErrorCode));

function getErrorCode(errors: ErrorNotificationData[]): ErrorCode {
  const errorCode = errors[0].errorCode?.toLowerCase() as ErrorCode;
  if (errorCodesSet.has(errorCode)) {
    return errorCode;
  }
  return ErrorCode.DEFAULT;
}

const trackErrorImpression = (error: string) => {
  track(
    UserEvents.VISIT_ERROR_IMPRESSION,
    { type: 'impression', error_details: error },
    { sendToPendo: true, sendToHqoTracking: true },
  );
};

export const ErrorModalContent = (): JSX.Element | null => {
  const { formatMessage } = useIntl();
  const { errors } = useSelector(getErrorNotifications);
  const dispatch = useDispatch();
  const errorHandlers = useErrorHandlers();
  const errorCode = getErrorCode(errors);

  const handleClose = useCallback(() => {
    errorHandlers[errorCode]?.();
    dispatch(hideErrorNotifications());
  }, [dispatch, errorCode, errorHandlers]);

  useEffect(() => {
    trackErrorImpression(errorCode);
  }, [errorCode]);

  return (
    <Modal onClose={handleClose} variant="small" hideMobileCloseButton>
      <ErrorNotification
        title={formatMessage({ id: `notifications.errors.${errorCode}.title` })}
        description={formatMessage({ id: `notifications.errors.${errorCode}.description` })}
        buttonText={formatMessage({ id: `notifications.errors.${errorCode}.buttonText` })}
        onClick={handleClose}
      />
    </Modal>
  );
};
