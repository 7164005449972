import { CenteredChevron, Wrapper } from './visit-item.styles';

import { DataPanel } from './data-panel';
import { DatePanel } from './date-panel';
import { FormattedMessage } from 'react-intl';
import { InfiniteLoaderItemProps } from '@hqo/react-components-library/dist/molecules/infinite-loader';
import { VisitDetailsLink } from './visit-details-link';
import { VisitUiType } from 'store/visits/types';
import { getVisitState } from 'store/visits/selectors';
import { useSelector } from 'react-redux';

interface VisitItemProps extends InfiniteLoaderItemProps {
  type: VisitUiType;
}

export const VisitItem = ({ index, style, type }: VisitItemProps): JSX.Element => {
  const { loading, data, error } = useSelector(getVisitState(type, index));

  if (loading) {
    return (
      <Wrapper style={style}>
        <FormattedMessage id="info.loading" />
      </Wrapper>
    );
  }

  if (error || !data?.id) {
    return (
      <Wrapper style={style}>
        <FormattedMessage id="info.error" />
      </Wrapper>
    );
  }

  const recurrenceData = { frequency: data?.frequency, endDate: data?.end_date, count: data?.count };

  return (
    <VisitDetailsLink index={index} style={style} type={type} visitId={data.id}>
      <DatePanel arrivalDate={data.arrival_date} index={index} recurrenceData={recurrenceData} />
      <DataPanel visitData={data} index={index} />
      <CenteredChevron size="sm" />
    </VisitDetailsLink>
  );
};
