import { VisitState, VisitUiType } from './types';

import { RootState } from 'store/reducer';
import { createSelector } from 'reselect';
import { initialVisitState } from 'store/visits/reducer';

export const visitsState = (state: RootState): RootState['visits'] => state.visits;

interface PaginatedVisitsState {
  data: VisitState[];
  total: number | null;
}

type PaginatedVisitsStateSelector = (state: RootState) => PaginatedVisitsState;
export const getPaginatedVisitsState = (type: VisitUiType): PaginatedVisitsStateSelector =>
  createSelector(visitsState, (state: RootState['visits']): PaginatedVisitsState => {
    switch (type) {
      case VisitUiType.PAST:
        return {
          data: state.pastVisitsData as unknown as VisitState[],
          total: state.pastVisitsTotal,
        };
      case VisitUiType.UPCOMING:
        return {
          data: state.upcomingVisitsData as unknown as VisitState[],
          total: state.upcomingVisitsTotal,
        };
      case VisitUiType.ALL:
      default:
        return {
          data: state.allVisitsData as unknown as VisitState[],
          total: state.allVisitsTotal,
        };
    }
  });

type VisitStateSelector = (state: RootState) => VisitState;
export const getVisitState = (type: VisitUiType, index: number): VisitStateSelector =>
  createSelector(
    getPaginatedVisitsState(type),
    (state: PaginatedVisitsState): VisitState => (state.data[index] as VisitState) ?? initialVisitState,
  );

export const getVisitStateById = (visitId: string): VisitStateSelector =>
  createSelector(
    visitsState,
    ({ mappedData }: RootState['visits']): VisitState =>
      (mappedData[visitId] as unknown as VisitState) ?? initialVisitState,
  );

export const createVisitLoadingState = createSelector(
  visitsState,
  (state: RootState['visits']): boolean => state.createVisitQueryState.loading,
);
