import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { VisitsPage } from 'pages/visits-page';
import { useFieldMetadata } from 'store/ui-metadata/hooks';
import { CreateOnlyPage } from 'pages/create-only-page';
import { useSelector } from 'react-redux';
import { uiMetadata } from '../../../../../store/ui-metadata/selectors';

export const VisitsRoute = (): JSX.Element | null => {
  const { path } = useRouteMatch();
  const viewVisitsEnabled = useFieldMetadata('view_visits_enabled');
  const uiMetadataState = useSelector(uiMetadata);
  const LandingPage = viewVisitsEnabled ? VisitsPage : CreateOnlyPage;

  if (!uiMetadataState) {
    return null;
  }

  return (
    <Switch>
      <Route path={path} component={LandingPage} />
    </Switch>
  );
};
