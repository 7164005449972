import { IconContentProps } from '@hqo/react-components-library/dist/icons';
import styled from 'styled-components';

export const padding: Record<NonNullable<IconContentProps['size']>, number | string> = {
  lg: '10px',
  md: '8px',
  none: '',
  sm: '6px',
  xlg: '22px',
  xs: '4px',
  's/m': '6px',
  'm/l': '8px',
};

export const IconWrapper = styled.div<Pick<IconContentProps, 'size'>>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: ${({ size = 'sm' }) => padding[size]};
  > .icon {
    color: currentColor;
  }
`;

export const IconBackground = styled.div`
  background-color: currentColor;
  opacity: 0.2;
  border-radius: 50%;
  position: absolute;
  width: 100%;
  height: 100%;
`;
