import { FetchVisitsError, FetchVisitsParams, FetchVisitsResponse, Visit, VisitState, VisitsState } from './types';
import {
  cancelVisit,
  createVisit,
  fetchAllVisits,
  fetchPastVisits,
  fetchUpcomingVisits,
  fetchVisit,
  initializeVisitorRegistration,
  updateVisit,
} from './actions';

import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { isStandardPaginationResult } from '../utils';

export const initialState: VisitsState = {
  loading: false,
  mappedData: {},
  visitorRegistrationInitialized: false,

  upcomingVisitsData: [],
  upcomingVisitsTotal: null,

  allVisitsData: [],
  allVisitsTotal: null,

  pastVisitsData: [],
  pastVisitsTotal: null,

  createVisitQueryState: {
    error: null,
    loading: false,
  },
};

export const initialVisitState: VisitState = {
  loading: false,
  error: null,
  data: null,
};

const loading = createReducer(initialState.loading)
  .handleAction(fetchUpcomingVisits.request, () => true)
  .handleAction(fetchUpcomingVisits.failure, () => false)
  .handleAction(fetchUpcomingVisits.success, () => false)
  .handleAction(fetchPastVisits.request, () => true)
  .handleAction(fetchPastVisits.failure, () => false)
  .handleAction(fetchPastVisits.success, () => false)
  .handleAction(fetchAllVisits.request, () => true)
  .handleAction(fetchAllVisits.failure, () => false)
  .handleAction(fetchAllVisits.success, () => false);

const storeFetchedVisits = (
  state: Record<string, VisitState>,
  { payload }: { payload: FetchVisitsResponse },
): Record<string, VisitState> => {
  const visits: Visit[] = isStandardPaginationResult(payload.result) ? payload.result.data.visits : payload.result.data;
  return {
    ...state,
    ...visits.reduce(
      (result, visit) => ({
        ...result,
        [visit.id]: {
          ...initialVisitState,
          data: visit,
        },
      }),
      {} as Record<string, VisitState>,
    ),
  };
};

const mappedData = createReducer(initialState.mappedData)
  .handleAction(fetchUpcomingVisits.success, storeFetchedVisits)
  .handleAction(fetchPastVisits.success, storeFetchedVisits)
  .handleAction(fetchAllVisits.success, storeFetchedVisits)
  .handleAction(fetchVisit.request, (state, action) => ({
    ...state,
    [action.payload.visitId]: {
      ...initialVisitState,
      ...state[action.payload.visitId],
      loading: true,
    },
  }))
  .handleAction(fetchVisit.success, (state, action) => ({
    ...state,
    [action.payload.params.visitId]: {
      error: null,
      data: action.payload.result,
      loading: false,
    },
  }))
  .handleAction(fetchVisit.failure, (state, action) => ({
    ...state,
    [action.payload.params.visitId]: {
      error: action.payload.error,
      data: null,
      loading: false,
    },
  }))
  .handleAction(cancelVisit.request, (state, action) => ({
    ...state,
    [action.payload.visitId]: {
      ...state[action.payload.visitId],
      loading: true,
    },
  }))
  .handleAction(cancelVisit.success, (state, action) => {
    const newState = { ...state };
    delete newState[action.payload.visitId];

    return newState;
  })
  .handleAction(cancelVisit.failure, (state, action) => ({
    ...state,
    [action.payload.params.visitId]: {
      ...state[action.payload.params.visitId],
      loading: false,
      error: action.payload.error,
    },
  }))
  .handleAction(createVisit.success, (state, action) => ({
    ...state,
    [action.payload.id]: {
      ...initialVisitState,
      data: action.payload,
    },
  }))
  .handleAction(updateVisit.request, (state, action) => ({
    ...state,
    [action.payload.params.visitId]: {
      ...state[action.payload.params.visitId],
      loading: true,
    },
  }))
  .handleAction(updateVisit.success, (state, action) => ({
    ...state,
    [action.payload.result.id]: {
      ...initialVisitState,
      data: action.payload.result,
      loading: false,
    },
  }))
  .handleAction(updateVisit.failure, (state, action) => ({
    ...state,
    [action.payload.params.visitId]: {
      ...state[action.payload.params.visitId],
      loading: false,
      error: action.payload.error,
    },
  }));

const visitorRegistrationInitialized = createReducer(initialState.visitorRegistrationInitialized).handleAction(
  initializeVisitorRegistration,
  () => true,
);

const isItemInCurrentRequest = (
  index: number,
  { offset, limit }: Pick<FetchVisitsParams, 'limit' | 'offset'>,
): boolean => index >= offset && index < limit + offset;

const handleFetchVisitsRequest = (state: VisitState[], { payload }: { payload: FetchVisitsParams }) => {
  const length = Math.max(state.length, payload.limit + payload.offset);

  return Array.from({ length }, (_, index: number) => {
    const oldVisitState = state[index];

    return {
      ...initialVisitState,
      ...oldVisitState,
      ...(isItemInCurrentRequest(index, payload) ? { loading: true } : {}),
    };
  });
};

const handleFetchVisitsSuccess = (state: VisitState[], { payload }: { payload: FetchVisitsResponse }) => {
  const data: Visit[] = isStandardPaginationResult(payload.result) ? payload.result.data.visits : payload.result.data;

  if (payload.result.total === data.length) {
    return data.map((visit) => ({ ...initialVisitState, data: visit }));
  }

  const length = Math.min(Math.max(state.length, payload.result.total), payload.result.total);

  return Array.from({ length }, (_, index: number) => {
    if (!isItemInCurrentRequest(index, payload.params)) {
      return state[index] ?? initialVisitState;
    }

    const newVisitIndex = index - payload.params.offset;

    return {
      loading: false,
      error: null,
      data: data[newVisitIndex] ?? initialVisitState.data,
    };
  });
};

const handleFetchVisitsError = (state: VisitState[], { payload }: { payload: FetchVisitsError }) => {
  const length = Math.max(state.length, payload.params.limit + payload.params.offset);

  return Array.from({ length }, (_, index: number) => {
    const oldVisitState = state[index];

    return isItemInCurrentRequest(index, payload.params)
      ? {
          loading: false,
          data: null,
          error: payload.error,
        }
      : oldVisitState;
  });
};

const upcomingVisitsData = createReducer(initialState.upcomingVisitsData)
  .handleAction(fetchUpcomingVisits.request, handleFetchVisitsRequest)
  .handleAction(fetchUpcomingVisits.success, handleFetchVisitsSuccess)
  .handleAction(fetchUpcomingVisits.failure, handleFetchVisitsError)
  .handleAction(cancelVisit.success, () => [])
  .handleAction(cancelVisit.failure, () => [])
  .handleAction(createVisit.success, () => [])
  .handleAction(updateVisit.success, () => []);

const upcomingVisitsTotal = createReducer(initialState.upcomingVisitsTotal)
  .handleAction(fetchUpcomingVisits.request, (state) => state)
  .handleAction(fetchUpcomingVisits.failure, (state) => state)
  .handleAction(fetchUpcomingVisits.success, (_state, action) => action.payload.result.total)
  .handleAction(cancelVisit.success, () => null)
  .handleAction(cancelVisit.failure, () => null)
  .handleAction(createVisit.success, () => null);

const pastVisitsData = createReducer(initialState.pastVisitsData)
  .handleAction(fetchPastVisits.request, handleFetchVisitsRequest)
  .handleAction(fetchPastVisits.success, handleFetchVisitsSuccess)
  .handleAction(fetchPastVisits.failure, handleFetchVisitsError)
  .handleAction(cancelVisit.success, () => [])
  .handleAction(cancelVisit.failure, () => []);

const pastVisitsTotal = createReducer(initialState.pastVisitsTotal)
  .handleAction(fetchPastVisits.request, (state) => state)
  .handleAction(fetchPastVisits.failure, (state) => state)
  .handleAction(fetchPastVisits.success, (_state, action) => action.payload.result.total)
  .handleAction(cancelVisit.success, () => null)
  .handleAction(cancelVisit.failure, () => null);

const allVisitsData = createReducer(initialState.allVisitsData)
  .handleAction(fetchAllVisits.request, handleFetchVisitsRequest)
  .handleAction(fetchAllVisits.success, handleFetchVisitsSuccess)
  .handleAction(fetchAllVisits.failure, handleFetchVisitsError)
  .handleAction(cancelVisit.success, () => [])
  .handleAction(cancelVisit.failure, () => []);

const allVisitsTotal = createReducer(initialState.allVisitsTotal)
  .handleAction(fetchAllVisits.request, (state) => state)
  .handleAction(fetchAllVisits.failure, (state) => state)
  .handleAction(fetchAllVisits.success, (_state, action) => action.payload.result.total)
  .handleAction(cancelVisit.success, () => null)
  .handleAction(cancelVisit.failure, () => null);

const createVisitQueryState = createReducer(initialState.createVisitQueryState)
  .handleAction(createVisit.request, () => ({
    error: null,
    loading: true,
  }))
  .handleAction(createVisit.success, () => ({
    error: null,
    loading: false,
  }))
  .handleAction(createVisit.failure, (_state, action) => ({
    error: action.payload.error,
    loading: false,
  }));

export default combineReducers({
  loading,
  mappedData,
  visitorRegistrationInitialized,
  upcomingVisitsData,
  upcomingVisitsTotal,
  pastVisitsData,
  pastVisitsTotal,
  allVisitsData,
  allVisitsTotal,
  createVisitQueryState,
});
