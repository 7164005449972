import * as buildingEpics from 'store/building/epics';
import * as altBuildingsEpics from 'store/alt-buildings/epics';
import * as escortsEpics from 'store/escorts/epics';
import * as notificationsEpics from 'store/errors/epics';
import * as tenantBuildingEpics from 'store/tenant-building/epics';
import * as themeEpics from 'store/theme/epics';
import * as uiMetadataEpics from 'store/ui-metadata/epics';
import * as userEpics from 'store/user/epics';
import * as visitsEpics from 'store/visits/epics';
import * as hostsEpics from 'store/hosts/epics';

import { combineEpics } from 'redux-observable';

export default combineEpics(
  ...Object.values(userEpics),
  ...Object.values(uiMetadataEpics),
  ...Object.values(visitsEpics),
  ...Object.values(buildingEpics),
  ...Object.values(altBuildingsEpics),
  ...Object.values(escortsEpics),
  ...Object.values(tenantBuildingEpics),
  ...Object.values(themeEpics),
  ...Object.values(notificationsEpics),
  ...Object.values(hostsEpics),
);
