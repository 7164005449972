import { useEffect } from 'react';
import { currentUser } from 'store/user/selectors';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'react-redux';
import { LDUser } from 'launchdarkly-js-client-sdk';
import { LAUNCH_DARKLY_ANONYMOUS_EMAIL } from 'utils/constants';

const initialLDUser: LDUser = {
  key: LAUNCH_DARKLY_ANONYMOUS_EMAIL,
  anonymous: true,
};

export const useLaunchDarklyIdentify = (): void => {
  const ldClient = useLDClient();
  const user = useSelector(currentUser);

  useEffect(() => {
    if (!user || !ldClient) {
      return;
    }
    ldClient.identify({
      ...initialLDUser,
      email: user.email,
      key: user.email,
      anonymous: false,
    });
  }, [ldClient, user]);
};
