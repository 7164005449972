import { DynamicFormDataDto } from '@hqo/dynamic-forms-sdk/types/form/form-data.types';
import { VisitCreationFlow } from 'components/visit-form/types/visit-creations-flows';
import { VisitorType } from 'store/api-client/api-client.types';

export enum HostType {
  SELF = 'self',
  ALT = 'alternative',
}

export enum SpecialVisitType {
  VIP = 'VIP',
  ESCORT = 'ESCORT',
}

export enum VisitorFrequencyEnum {
  ONCE = 'once',
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
  SEMI_AMMUALLY = 'semi-annually',
  ANNUALLY = 'annual',
}

export interface VisitFormValues {
  id: string | null;
  visitCreationFlow: VisitCreationFlow | null;
  visitors: VisitorFormValues[];
  date: VisitDateFormValues;
  host: VisitHostFormValues;
  hostType: HostType | null;
  escort: VisitEscortFormValues;
  specialVisitType: SpecialVisitType | null;
  visit_type_id: number | null;
  custom_fields?: DynamicFormDataDto;
}

export interface VisitorFormValues {
  id: string | null;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  type: VisitorType;
  checkInOptions: string;
  checkInInstructions: string;
  reasonForVisit: string;
  isPremier: boolean;
  emailRecipients: string[];
}

export interface VisitDateFormValues {
  visitDate: Date | null;
  visitEndDate: Date | null;
  startTime: string | null;
  endTime: string | null;
  timeZone?: string;
  frequency: string | null;
  endDate: Date | null;
  count: number | null;
}

export interface VisitEscortFormValues {
  id: string | null;
  instructions: string;
}

export interface NotificationType {
  notification: boolean;
  emailMessage: boolean;
  textMessage: boolean;
}

export interface VisitHostFormValues {
  hostName: string;
  email: string;
  floor?: string | null;
  suite?: string | null;
  notificationType: NotificationType;
}
