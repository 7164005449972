import { AltBuilding, AltBuildingsState } from './types';

import { RootState } from '../reducer';
import { createSelector } from 'reselect';

export const altBuildingsStateSelector = (state: RootState): AltBuildingsState => state?.altBuildings;

export const selectAltBuildings = createSelector(
  altBuildingsStateSelector,
  (altBuildingsState) => altBuildingsState?.altBuildings,
);

export const selectAltBuildingsStatus = createSelector(
  altBuildingsStateSelector,
  (altBuildingsState) => altBuildingsState?.getAltBuildings.status,
);

export const selectCurrentAltBuilding = createSelector(
  altBuildingsStateSelector,
  (altBuildingsState) => altBuildingsState?.currentAltBuilding,
);

export const selectAltBuildingsByUuid = createSelector([selectAltBuildings], (altBuildings) => {
  const altBuildingsByUuid: Record<string, AltBuilding> = {};
  if (altBuildings?.length) {
    altBuildings.forEach((altBuilding) => {
      altBuildingsByUuid[altBuilding.alt_building_uuid] = altBuilding;
    });
    return altBuildingsByUuid;
  }
  return altBuildingsByUuid;
  return {};
});
