import { Redirect, useRouteMatch } from 'react-router-dom';

import React from 'react';
import { useSupportsVisitFiltering } from 'store/ui-metadata/hooks';

export const DefaultRedirect: React.FC = (): JSX.Element => {
  const { url } = useRouteMatch();
  const useTabs = useSupportsVisitFiltering();
  const listMode = useTabs ? 'upcoming' : 'all';

  return <Redirect to={`${url}/${listMode}`} />;
};
