import { Hosts } from './types';
import { FailureActionPayload } from 'store/errors/types';
import { OwnerIdParam } from 'shared/types';
import { createAsyncAction, createAction } from 'typesafe-actions';

export const fetchHosts = createAsyncAction('hosts/FETCH_REQUEST', 'hosts/FETCH_SUCCESS', 'hosts/FETCH_FAILURE')<
  OwnerIdParam,
  Hosts,
  FailureActionPayload
>();

export const saveHostSearchValue = createAction('hosts/SAVE_SEARCH_VALUE')<string>();
