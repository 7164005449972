import { useDispatch, useSelector } from 'react-redux';

import { OwnerIdParam } from 'shared/types';
import { fetchTenantBuilding } from './actions';
import { tenantBuildingState } from 'store/tenant-building/selectors';
import { useEffect } from 'react';
import { useParams } from 'react-router';

export const useFetchTenantBuilding = (): boolean => {
  const { ownerId } = useParams<OwnerIdParam>();
  const dispatch = useDispatch();
  const { loading } = useSelector(tenantBuildingState);

  useEffect(() => {
    dispatch(fetchTenantBuilding.request(ownerId));
  }, [dispatch, ownerId]);

  return loading;
};
