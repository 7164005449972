import { ApiClient } from './api-client';
import { UserState } from 'store/user/types';
import { ConfigState } from 'store/config/types';
import { AltBuildingsState } from 'store/alt-buildings/types';

interface ApiClientState {
  user: Pick<UserState, 'token'>;
  config: Pick<ConfigState, 'apiUrl' | 'buildingUuid'>;
  altBuildings?: Pick<AltBuildingsState, 'currentAltBuilding'>;
}

export const apiClient = (
  state: ApiClientState,
  baseUrlOverride?: string | null,
  authTokenOverride?: string | null,
): ApiClient => {
  const baseUrl = baseUrlOverride ?? state.config.apiUrl;
  const authToken = authTokenOverride ?? state.user.token;
  const { buildingUuid } = state.config;
  const altBuildingUuid = state.altBuildings?.currentAltBuilding?.alt_building_uuid;

  return new ApiClient(baseUrl, authToken, altBuildingUuid, buildingUuid);
};
