import { ErrorNotification as Notification } from '@hqo/react-components-library/dist/molecules/errorNotification';
import { useIntl } from 'react-intl';

interface ErrorNotificationProps {
  errorCode: string;
  handleClick: () => void;
  showButton: boolean;
}

export const ErrorNotification = ({ errorCode, handleClick, showButton }: ErrorNotificationProps): JSX.Element => {
  const { formatMessage } = useIntl();
  return (
    <Notification
      title={formatMessage({ id: `notifications.criticalErrors.${errorCode}.title` })}
      description={formatMessage({ id: `notifications.criticalErrors.${errorCode}.description` })}
      buttonText={formatMessage({ id: `notifications.criticalErrors.${errorCode}.buttonText` })}
      onClick={handleClick}
      showButton={showButton}
    />
  );
};
