import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { Epic } from 'redux-observable';
import { RootAction } from 'store/actions';
import { RootDependencies } from 'store/dependencies';
import { RootState } from 'store/reducer';
import { fetchBuilding as fetchBuildingAction } from './actions';
import { isActionOf } from 'typesafe-actions';
import { of } from 'rxjs';
import { getErrorCode } from 'store/utils';

export const fetchBuilding: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(fetchBuildingAction.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) => {
      return apiClient(state)
        .fetchBuilding(payload)
        .pipe(
          map(({ response }) => fetchBuildingAction.success(response)),
          catchError((error: Error) => of(fetchBuildingAction.failure({ error, errorCode: getErrorCode(error) }))),
        );
    }),
  );
