import { getAltBuildings, setCurrentAltBuilding } from '../store/alt-buildings/actions';
import { selectAltBuildings, selectAltBuildingsByUuid } from '../store/alt-buildings/selectors';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AltBuilding } from '../store/alt-buildings/types';
import { SelectBuildingEventType } from '../shared/types/alt-building';
import { configSelector } from '../store/config/selectors';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { ViewParams } from 'shared/types/view-params';

export const useAltBuildings = (): AltBuilding[] => {
  const dispatch = useDispatch();
  const altBuildings = useSelector(selectAltBuildings);
  const search = useSearchParams<Partial<ViewParams>>();
  const config = useSelector(configSelector);
  const buildingUuid = search.buildingUuid || config.buildingUuid;

  useEffect(() => {
    if (!altBuildings && buildingUuid) {
      dispatch(getAltBuildings.request(buildingUuid));
    }
  }, [dispatch, buildingUuid, altBuildings]);

  return altBuildings as AltBuilding[];
};

export const useSetAltBuildingState = (
  selectedAltBuilding: AltBuilding | undefined,
  onClose: VoidFunction,
): (() => void) => {
  const dispatch = useDispatch();
  return useCallback(() => {
    if (selectedAltBuilding) {
      dispatch(setCurrentAltBuilding(selectedAltBuilding));
      onClose();
    }
  }, [dispatch, onClose, selectedAltBuilding]);
};

export const useSetSelectedAltBuilding = (
  setSelectedAltBuilding: React.Dispatch<React.SetStateAction<AltBuilding>>,
): ((event: SelectBuildingEventType) => void) => {
  const altBuildingsByUuid = useSelector(selectAltBuildingsByUuid);

  return useCallback(
    (event: SelectBuildingEventType) => {
      setSelectedAltBuilding(altBuildingsByUuid[event.target.value as string]);
    },
    [setSelectedAltBuilding, altBuildingsByUuid],
  );
};
