import React, { Suspense, lazy } from 'react';
import { useParams } from 'react-router';
import { useVisitState } from 'store/visits/hooks';

const VisitForm = lazy(() => import('components/visit-form/visit-form'));

export const EditVisitPage: React.FC = () => {
  const { visitId } = useParams<{ visitId: string }>();
  const { loading, data } = useVisitState(visitId, true);

  return (
    <Suspense fallback={null}>
      <VisitForm visit={data} visitLoading={loading} isEditMode />
    </Suspense>
  );
};
