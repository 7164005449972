import { createAction, createAsyncAction } from 'typesafe-actions';

import { AltBuilding } from './types';

export const getAltBuildings = createAsyncAction(
  'GET_ALT_BUILDINGS_REQUEST',
  'GET_ALT_BUILDINGS_SUCCESS',
  'GET_ALT_BUILDINGS_FAILURE',
)<string, Array<AltBuilding>, Error>();

export const setCurrentAltBuilding = createAction('SET_CURRENT_ALT_BUILDING')<AltBuilding>();
