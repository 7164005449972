import { ContentWrapper, HeaderWrapper, TabsWrapper, Wrapper } from './visitors-layout.styles';

import { Header } from './header';
import React from 'react';
import { Tabs } from './tabs';
import { useSupportsVisitFiltering } from 'store/ui-metadata/hooks';

interface VisitorLayoutProps {
  children: React.ReactNode;
}

export const VisitorLayout = ({ children }: VisitorLayoutProps): JSX.Element => {
  const showTabs = useSupportsVisitFiltering();
  const wrapperClassNames = !showTabs ? 'non-tabbed' : '';

  return (
    <Wrapper className={wrapperClassNames}>
      <HeaderWrapper>
        <Header />
      </HeaderWrapper>
      {showTabs && (
        <TabsWrapper>
          <Tabs />
        </TabsWrapper>
      )}
      <ContentWrapper>{children}</ContentWrapper>
    </Wrapper>
  );
};
