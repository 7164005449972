import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { AppContentWrapper } from 'components/root/app-content-wrapper';
import { VISITS_SUB_PATH } from 'shared/consts';
import { VisitsRedirect } from 'components/root/router/visitor-registration-route/visits-redirect';
import { VisitsRoute } from 'components/root/router/visitor-registration-route/visits-route';

export const VisitorRegistrationRoute = (): JSX.Element => {
  const { path } = useRouteMatch();

  return (
    <AppContentWrapper>
      <Switch>
        <Route path={`${path}/${VISITS_SUB_PATH}`} component={VisitsRoute} />
        <Route component={VisitsRedirect} />
      </Switch>
    </AppContentWrapper>
  );
};
