import { ROOT_PATH, UserEvents, VISITS_SUB_PATH } from 'shared/consts';
import { generatePath, useRouteMatch } from 'react-router-dom';

import { OwnerParams } from 'shared/types';
import React from 'react';
import { StyledLink } from './new-visitor-link.styles';
import { track } from '@hqo/web-tracking';

interface NewVisitorLinkProps {
  className?: string;
  children: React.ReactNode;
}

const trackClick = (): void => {
  track(UserEvents.ADD_VISITOR_CLICK, { type: 'action' }, { sendToPendo: true, sendToHqoTracking: true });
};

export const NewVisitorLink = ({ className, children }: NewVisitorLinkProps): JSX.Element => {
  const {
    params: { ownerId, ownerType },
  } = useRouteMatch<OwnerParams>();

  return (
    <StyledLink
      to={generatePath(`${ROOT_PATH}/${VISITS_SUB_PATH}/new`, { ownerId, ownerType })}
      className={className}
      data-cy="new-visitor-link"
      data-testid="new-visitor-link"
      onClick={trackClick}
    >
      {children}
    </StyledLink>
  );
};
