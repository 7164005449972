import { DefaultThemeWithFonts } from '@hqo/react-components-library/dist/tenant-web/elements/theme';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';

import { OwnerParams } from 'shared/types';
import { getBuildingTheme } from 'store/theme/actions';
import { hqoTheme } from '@hqo/react-components-library/dist/hqo-theme-provider';
import { lighten } from 'polished';
import { selectBrandTheme } from 'store/theme/selectors';
import { useParams } from 'react-router';

export const useBuildingTheme = (): DefaultThemeWithFonts => {
  const dispatch = useDispatch();
  const brandTheme = useSelector(selectBrandTheme);
  const { ownerId } = useParams<OwnerParams>();

  useEffect(() => {
    if (ownerId) {
      dispatch(getBuildingTheme.request(ownerId));
    }
  }, [dispatch, ownerId]);

  return useMemo(() => {
    if (brandTheme) {
      return {
        colors: {
          ...hqoTheme.colors,
          $primary: brandTheme.primary_color,
          $secondary: brandTheme.secondary_color,
          primary: brandTheme.primary_color,
          primaryHover: lighten(0.1, brandTheme.primary_color),
          secondary: brandTheme.secondary_color,
          hqoSecondary: brandTheme.secondary_color,
          fontColor: brandTheme.primary_font_color ?? hqoTheme.colors.greys.adminBlack,
        },
        fonts: brandTheme.font?.body?.font_family ? [`${brandTheme.font?.body?.font_family}`, ...hqoTheme.fonts] : null,
      } as DefaultThemeWithFonts;
    }
    return hqoTheme;
  }, [brandTheme]);
};
