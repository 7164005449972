import { Redirect, Route, RouteProps } from 'react-router-dom';

import React from 'react';
import { hasToken } from 'store/user/selectors';
import { useCurrentUser } from 'hooks/use-current-user.hook';
import { useSelector } from 'react-redux';
import { ERROR_PATH } from 'shared/consts';

interface PrivateRouteProps extends RouteProps {
  redirectTo?: string;
}
export const PrivateRoute: React.FC<PrivateRouteProps> = ({
  redirectTo = ERROR_PATH,
  ...routeProps
}: PrivateRouteProps): JSX.Element | null => {
  const [currentUser, loading] = useCurrentUser();
  const isTokenStored = useSelector(hasToken);

  if (currentUser) {
    return <Route {...routeProps} />;
  }

  if (!isTokenStored && !loading) {
    return <Redirect to={redirectTo} />;
  }

  return null;
};
