import { AllVisitorsList, PastVisitorsList, UpcomingVisitorsList } from 'components/visitors-list';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { DefaultRedirect } from './default-redirect';
import { NewVisitorPage } from 'pages/new-visit-page';
import { VisitDetailsPage } from 'pages/visit-details-page';
import { VisitorLayout } from 'components/visitors-layout';
import { PendingVisitPage } from 'pages/pending-visit-page';
import { EditVisitPage } from 'pages/edit-visit-page';

export const VisitsPage = (): JSX.Element => {
  const { path } = useRouteMatch();

  return (
    <VisitorLayout>
      <Switch>
        <Route path={`${path}/all`} component={AllVisitorsList} exact />
        <Route path={`${path}/upcoming`} component={UpcomingVisitorsList} exact />
        <Route path={`${path}/upcoming/PENDING`} component={PendingVisitPage} exact />
        <Route path={`${path}/past`} component={PastVisitorsList} exact />
        <Route path={`${path}/new`} component={NewVisitorPage} />
        <Route path={`${path}/:visitUiType(upcoming|past|all)/:visitId`} component={VisitDetailsPage} exact />
        <Route path={`${path}/:visitUiType(upcoming|past|all)/:visitId/edit`} component={EditVisitPage} />
        <Route component={DefaultRedirect} />
      </Switch>
    </VisitorLayout>
  );
};
