import { generatePath, useParams } from 'react-router';

import { OwnerParams } from 'shared/types';
import { push } from 'connected-react-router';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

export const useNavigate = (backPath: string): VoidFunction => {
  const params = useParams<OwnerParams>();
  const dispatch = useDispatch();

  return useCallback(() => {
    dispatch(push(generatePath(backPath, { ...params })));
  }, [dispatch, params, backPath]);
};
