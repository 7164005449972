import {
  VisitDataPanel,
  VisitMainDataPanel,
  VisitStatus,
  VisitStatusLabel,
  VisitStatusPanel,
  VisitorNames,
  VisitorType,
} from './data-panel.styles';

import { FormattedMessage } from 'react-intl';
import { Visit } from 'store/visits/types';
import { getDataLabel } from 'components/visitors-list/infinite-loading-list/visit-item/utils';

interface DataPanelProps {
  visitData: Visit;
  index: number;
}

export const DataPanel = ({ index, visitData: data }: DataPanelProps): JSX.Element => {
  const visitors = data?.visitors?.map((visitor) => `${visitor?.first_name} ${visitor?.last_name}`).join(', ');
  const visit_type = data?.special_visit_type;
  const status = data?.visit_status;
  const testId = getDataLabel(index, 'visit-status');

  return (
    <VisitDataPanel>
      <VisitMainDataPanel>
        <VisitorNames title={visitors} data-cy={getDataLabel(index, 'visitor-names')}>
          {visitors}
        </VisitorNames>
        <VisitorType data-cy={getDataLabel(index, 'visitor-type')}>{visit_type}</VisitorType>
      </VisitMainDataPanel>
      <VisitStatusPanel>
        {status && (
          <>
            <VisitStatusLabel>
              <FormattedMessage id="labels.status.title" />
            </VisitStatusLabel>
            <VisitStatus data-cy={testId} data-testid={testId}>
              <FormattedMessage id={`labels.status.type.${status}`} defaultMessage={status} />
            </VisitStatus>
          </>
        )}
      </VisitStatusPanel>
    </VisitDataPanel>
  );
};
