import { useDispatch } from 'react-redux';

import { useMiniappSdkEventHandler } from 'hooks/use-miniapp-sdk.hook';
import { GetJWTHandlerPayload } from '@hqo/hqo-miniapp-client-sdk';
import { updateToken } from 'store/user/actions';

const refreshTokenEventName = 'refreshTokenResponse';

export const useRefreshToken = (): void => {
  const dispatch = useDispatch();

  useMiniappSdkEventHandler(
    refreshTokenEventName,
    ({ token }: GetJWTHandlerPayload) => {
      dispatch(updateToken(token));
    },
    [dispatch],
  );
};
