import { createGlobalStyle, css } from 'styled-components';
import { zIndex } from 'shared/z-index';
import { mediaMaxWidth } from '@hqo/react-components-library/dist/device';

export const RootStyles = createGlobalStyle`
  body {
    margin: 0;
    font-family: ${({ theme }) => theme.fonts.join()};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  *, *:before, *:after {
    box-sizing: border-box;

    ${mediaMaxWidth(css`
      user-select: none;
    `)}
  }

  input, textarea {
    user-select: auto;
  }

  html, body, #root {
    min-height: 100vh;
    background-color: ${({ theme }) => theme.colors.universal.white};
  }

  .popover-body {
    z-index: ${zIndex('popover')};
  }
`;
