import { TenantBuilding, TenantBuildingState } from './types';

import { RootState } from 'store/reducer';
import { createSelector } from 'reselect';

export const tenantBuildingState = (state: RootState): TenantBuildingState => state.tenantBuilding;

export const tenantBuilding = createSelector(
  tenantBuildingState,
  (state: TenantBuildingState): TenantBuilding | null => state?.data,
);

export const tenantBuildingTimeZone = createSelector(
  tenantBuilding,
  (state: TenantBuilding | null): string | null => state?.timezone ?? null,
);

export const tenantBuildingLocale = createSelector(
  tenantBuilding,
  (state: TenantBuilding | null): string | null => state?.locale ?? null,
);
