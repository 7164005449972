import { RootState } from 'store/reducer';
import { User } from 'store/user/types';
import { createSelector } from 'reselect';

export const userState = (state: RootState): RootState['user'] => state.user;

export const userLoading = createSelector(userState, (state: RootState['user']): boolean => state.loading);
export const currentUser = createSelector(userState, (state: RootState['user']): User | null => state.user);
export const currentToken = createSelector(userState, (state: RootState['user']): string | null => state.token);
export const userError = createSelector(userState, (state: RootState['user']): Error | null => state.error);
export const hasToken = createSelector(userState, (state: RootState['user']): boolean => !!state.token);
export const selectTokenRefreshing = createSelector(
  userState,
  (state: RootState['user']): boolean => state.isTokenRefreshing,
);
