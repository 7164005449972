import { tenantBuildingLocale } from 'store/tenant-building/selectors';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { configSelector } from 'store/config/selectors';

export const useLocale = (): string => {
  const config = useSelector(configSelector);

  const buildingLocale = useSelector(tenantBuildingLocale);

  return useMemo(() => {
    return config?.locale ?? buildingLocale ?? navigator.language;
  }, [buildingLocale, config?.locale]);
};
