import { useSelector } from 'react-redux';

import { getErrorNotifications } from 'store/errors/selectors';
import { ErrorModalContent } from 'components/error-modal-content';

export const ErrorModal = (): JSX.Element | null => {
  const { hasErrorNotifications } = useSelector(getErrorNotifications);

  if (!hasErrorNotifications) {
    return null;
  }

  return <ErrorModalContent />;
};
