import { currentToken, selectTokenRefreshing } from 'store/user/selectors';
import { jwtDecode } from 'jwt-decode';
import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { RootState } from 'store/reducer';
import { getMiniappSdkClient } from 'hooks/use-miniapp-sdk.hook';
import { uiMetadata as uiMetadataSelector } from 'store/ui-metadata/selectors';
import {
  callActionAfterRefreshTokenAction,
  callActionAfterRefreshTokenTimeoutAction,
  getUserFailureAction,
  getUserRequestAction,
  getUserResponseAction,
  loginFailureAction,
  loginRequestAction,
  loginResponseAction,
  setTokenRefreshingAction,
  updateTokenAction,
} from 'store/user/consts';
import { CALL_HISTORY_METHOD, LOCATION_CHANGE } from 'connected-react-router';
import { callActionAfterRefreshToken, setTokenRefreshing } from 'store/user/actions';

const routerActions = [LOCATION_CHANGE, CALL_HISTORY_METHOD];
const loginActions = [loginRequestAction, loginResponseAction, loginFailureAction];
const getUserActions = [getUserRequestAction, getUserResponseAction, getUserFailureAction];
const refreshTokenActions = [
  updateTokenAction,
  setTokenRefreshingAction,
  callActionAfterRefreshTokenAction,
  callActionAfterRefreshTokenTimeoutAction,
];

const actionsToIgnore = [...routerActions, ...loginActions, ...getUserActions, ...refreshTokenActions];

const millisecondsInSecond = 1000;

const checkIsTokenExpired = (token: string) => {
  return token && jwtDecode(token).exp < Date.now() / millisecondsInSecond;
};

const refreshToken = (store: MiddlewareAPI<Dispatch<AnyAction>, RootState>) => {
  const miniappSdkClient = getMiniappSdkClient(uiMetadataSelector(store.getState())?.app_uuid);
  miniappSdkClient.refreshToken();
};

const analyzeTokenStatus = (store: MiddlewareAPI<Dispatch<AnyAction>, RootState>) => {
  if (!selectTokenRefreshing(store.getState())) {
    const isTokenExpired = checkIsTokenExpired(currentToken(store.getState()));

    if (isTokenExpired) {
      store.dispatch(setTokenRefreshing(true));
      refreshToken(store);
    }
  }
};

export const checkTokenExpirationMiddleware: Middleware<Record<string, unknown>, RootState> =
  (store) => (next) => (action) => {
    if (actionsToIgnore.includes(action.type)) {
      next(action);
      return;
    }

    analyzeTokenStatus(store);

    if (selectTokenRefreshing(store.getState())) {
      store.dispatch(callActionAfterRefreshToken(action));
    } else {
      next(action);
    }
  };
