import { ClipLoader } from '@hqo/react-components-library/dist/atoms/loader';
import { Wrapper } from './loader.styles';

interface LoaderProps {
  className?: string;
}

export const Loader = ({ className }: LoaderProps): JSX.Element => (
  <Wrapper className={className} data-cy="loader" data-testid="loader">
    <ClipLoader color="currentColor" />
  </Wrapper>
);
