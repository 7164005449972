import styled, { css } from 'styled-components';

import { mediaMaxWidth } from '@hqo/react-components-library/dist/device';

export const Wrapper = styled.section`
  display: grid;
  grid-template-columns: 260px 1fr;
  grid-template-rows: 156px 1fr;
  grid-template-areas: 'header header' 'tabs content';
  min-height: 100vh;

  &.non-tabbed {
    grid-template-columns: 0 1fr;
  }

  ${mediaMaxWidth(css`
    grid-template-columns: 1fr;
    grid-template-rows: 84px 30px 1fr;
    grid-template-areas: 'header' 'tabs' 'content';

    &.non-tabbed {
      grid-template-columns: 1fr;

      grid-template-rows: 84px 1fr;
      grid-template-areas: 'header' 'content';
    }
  `)}
`;

export const HeaderWrapper = styled.header`
  display: flex;
  width: 100%;
  align-items: flex-end;
  grid-area: header;
`;

export const TabsWrapper = styled.nav`
  grid-area: tabs;
`;

export const ContentWrapper = styled.div`
  grid-area: content;
`;
