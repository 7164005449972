import { StyledSwipeModal } from './modal.styles';

import React from 'react';

interface MobileModalProps {
  children: React.ReactNode;
  onClose?: VoidFunction;
  hideMobileCloseButton?: boolean;
}

export const MobileModal = ({
  children,
  onClose: handleClose,
  hideMobileCloseButton,
}: MobileModalProps): JSX.Element => (
  <StyledSwipeModal
    data-testid="mobile-modal-window"
    data-cy="modal-window"
    content={children}
    onClose={handleClose}
    reverseAnimation={false}
    hideMobileCloseButton={hideMobileCloseButton}
  />
);
