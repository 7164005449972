import styled, { css } from 'styled-components';
import { mediaMaxWidth } from '@hqo/react-components-library/dist/device';
import { VisitStatusTitleWrapper } from 'components/visit-modal/visit-status/visit-status.styles';
import { Button } from '@hqo/react-components-library/dist/atoms/button';
import { IconCircleWrapper } from '../../components/shared/icon-circle-wrapper';

export const VisitModalPendingWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  max-width: 280px;
  text-align: center;

  .visit-pending {
    width: 30px;
    margin: auto;
  }
`;

export const VisitPendingTitleWrapper = styled(VisitStatusTitleWrapper)`
  margin: 12px auto;

  ${mediaMaxWidth(css`
    margin: 16px auto;
  `)}
`;

export const PendingNotificationButton = styled(Button)`
  padding: 4px 24px;
  margin: 16px auto;
`;

export const PendingWrapper = styled(IconCircleWrapper)`
  color: ${({ theme }): string => theme.colors.admin.orangeAlt};
`;
