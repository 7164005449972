import { UNKNOWN, UserEvents } from 'shared/consts';
import { VisitUiType } from './types';

import moment from 'moment-timezone';
import { track } from '@hqo/web-tracking';

export const getCurrentBuildingDateTime = (type: VisitUiType): { before?: string; after?: string } => {
  const currentBuildingDateTimeInUTC = moment().utc().toISOString();

  if (type === VisitUiType.PAST) {
    return { before: currentBuildingDateTimeInUTC };
  }

  if (type === VisitUiType.UPCOMING) {
    return { after: currentBuildingDateTimeInUTC };
  }

  return {};
};

export const visitCanceled = (integrationName?: string): void => {
  track(UserEvents.VISIT_CANCELED, { type: 'action', service_provider: integrationName ?? UNKNOWN });
};
