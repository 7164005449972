import { externalLogin, getCurrentUser } from 'store/user/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useSearchParams } from './use-search-params.hook';
import { userState } from 'store/user/selectors';

type AuthParams = { authToken?: string; apiUrl?: string };

export const useExternalLogin = (): boolean => {
  const dispatch = useDispatch();
  const { user, error } = useSelector(userState);
  const shouldWaitForLogin = !user && !error;
  const { authToken, apiUrl } = useSearchParams<AuthParams>();

  useEffect((): void => {
    if (shouldWaitForLogin) {
      if (authToken) {
        dispatch(externalLogin.request({ authToken: authToken ?? null, apiUrl: apiUrl ?? null }));
      } else {
        dispatch(getCurrentUser.request());
      }
    }
  }, [dispatch, authToken, apiUrl, shouldWaitForLogin]);

  return shouldWaitForLogin;
};
