import { AppThemeProvider } from '@hqo/react-components-library/dist/tenant-web/elements/theme';
import { ErrorModal } from 'components/shared/error-modal';
import { PageLoader } from 'components/root/page-loader';
import React from 'react';
import { useBuildingTheme } from 'hooks/use-building-theme.hook';
import { useFetchBuilding } from 'store/building/hooks';
import { useFetchTenantBuilding } from 'store/tenant-building/hooks';
import { useFetchUiMetadata } from 'store/ui-metadata/hooks';
import { CustomFonts } from 'components/custom-fonts';
import { AppContentWrapperStyles } from 'components/root/app-content-wrapper/app-content-wrapper.styles';

interface AppContentWrapperProps {
  children: React.ReactNode;
}

export const AppContentWrapper = ({ children }: AppContentWrapperProps): JSX.Element => {
  const uiMetadataLoading = useFetchUiMetadata();
  const buildingLoading = useFetchBuilding();
  const tenantBuildingLoading = useFetchTenantBuilding();
  const theme = useBuildingTheme();

  if (buildingLoading || uiMetadataLoading || tenantBuildingLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <AppContentWrapperStyles customThemeFonts={theme.fonts} />
      <CustomFonts />
      <AppThemeProvider theme={theme}>
        {children}
        <ErrorModal />
      </AppThemeProvider>
    </>
  );
};
