import { ExternalAuthPayload, UserLoginResponse } from './types';

import { FailureActionPayload } from 'store/errors/types';
import {
  callActionAfterRefreshTokenAction,
  callActionAfterRefreshTokenTimeoutAction,
  getUserFailureAction,
  getUserRequestAction,
  getUserResponseAction,
  loginFailureAction,
  loginRequestAction,
  loginResponseAction,
  setTokenRefreshingAction,
  updateTokenAction,
} from 'store/user/consts';
import { PayloadAction, createAction, createAsyncAction } from 'typesafe-actions';

export const externalLogin = createAsyncAction(loginRequestAction, loginResponseAction, loginFailureAction)<
  ExternalAuthPayload,
  UserLoginResponse,
  FailureActionPayload
>();

export const getCurrentUser = createAsyncAction(getUserRequestAction, getUserResponseAction, getUserFailureAction)<
  void,
  Pick<UserLoginResponse, 'user'>,
  FailureActionPayload
>();

export const updateToken = createAction(updateTokenAction)<string>();

export const setTokenRefreshing = createAction(setTokenRefreshingAction)<boolean>();

export const callActionAfterRefreshToken = createAction(callActionAfterRefreshTokenAction)<
  PayloadAction<string, unknown>
>();

export const callActionAfterRefreshTokenTimeout = createAction(
  callActionAfterRefreshTokenTimeoutAction,
)<FailureActionPayload>();
