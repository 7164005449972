import { UNKNOWN, UserEvents } from 'shared/consts';
import { useDispatch, useSelector } from 'react-redux';

import { initializeVisitorRegistration } from 'store/visits/actions';
import { track } from '@hqo/web-tracking';
import { uiMetadata } from 'store/ui-metadata/selectors';
import { useAnalytics } from 'hooks/use-analytics.hook';
import { useEffect } from 'react';
import { visitsState } from 'store/visits/selectors';
import { hasNonNullValue } from './utils';

export const useVisitsInitializedState = (): boolean => {
  const { allVisitsTotal, upcomingVisitsTotal, pastVisitsTotal, visitorRegistrationInitialized } =
    useSelector(visitsState);

  return hasNonNullValue(allVisitsTotal, upcomingVisitsTotal, pastVisitsTotal) && !visitorRegistrationInitialized;
};

/**
 * Invokes useAnalytics hook
 * & sends 'VISITOR_REGISTRATION_INITIALIZED' Pendo event only if miniapp is fully loaded
 * and event has not been fired yet
 */
export const useTracking = (): void => {
  const visitsInitializedState = useVisitsInitializedState();
  const metadata = useSelector(uiMetadata);
  const dispatch = useDispatch();
  useAnalytics();

  useEffect(() => {
    if (visitsInitializedState) {
      track(
        UserEvents.VISITOR_REGISTRATION_INITIALIZED,
        {
          type: 'impression',
          service_provider: metadata?.integration_name ?? UNKNOWN,
        },
        { sendToPendo: true, sendToHqoTracking: true },
      );

      dispatch(initializeVisitorRegistration());
    }
  }, [visitsInitializedState, metadata, dispatch]);
};
