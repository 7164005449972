import { Tab } from './tab';
import { UserEvents } from 'shared/consts';
import { VisitUiType } from 'store/visits/types';
import { Wrapper } from './tabs.styles';
import { track } from '@hqo/web-tracking';

function trackPastVisitClick() {
  track(UserEvents.PAST_VISIT_CLICK, { type: 'action' }, { sendToPendo: true, sendToHqoTracking: true });
}
export const Tabs = (): JSX.Element => {
  return (
    <Wrapper>
      <Tab type={VisitUiType.UPCOMING} />
      <Tab type={VisitUiType.PAST} onClick={trackPastVisitClick} />
    </Wrapper>
  );
};
