import { tenantBuildingTimeZone } from 'store/tenant-building/selectors';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const getCurrentUserTimeZone = (): string => Intl.DateTimeFormat().resolvedOptions().timeZone;

export const useTimeZone = (): string => {
  const buildingTimeZone = useSelector(tenantBuildingTimeZone);

  return useMemo(() => {
    return buildingTimeZone ?? getCurrentUserTimeZone();
  }, [buildingTimeZone]);
};
