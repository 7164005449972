export enum FrequencyEnum {
  DAILY = 'daily',
  WORK_DAYS = 'work-days',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
  ANNUALLY = 'annual',
}

export enum RecurrenceEnum {
  OCCURENCES_COUNT = 'occurences_count',
  DATE = 'date',
  NEVER = 'never',
}
