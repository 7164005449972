import { useState } from 'react';
import { useIntl } from 'react-intl';

import { useSetAltBuildingState } from '../../../hooks/use-alt-buildings.hook';
import { AltBuilding } from '../../../store/alt-buildings/types';
import { ButtonContainer, CloseButton, SwitchBuildingButton } from './styles';
import { useSelector } from 'react-redux';
import { selectCurrentAltBuilding } from '../../../store/alt-buildings/selectors';
import { AltBuildingModalSelect } from '../alt-building-modal-select/component';

interface SwitchAltBuildingsModalProps {
  altBuildings: Array<AltBuilding>;
  onClose: VoidFunction;
}

export const AltBuildingModalBody = ({ altBuildings, onClose }: SwitchAltBuildingsModalProps): JSX.Element => {
  const intl = useIntl();
  const currentAltBuilding = useSelector(selectCurrentAltBuilding);
  const [selectedAltBuilding, setSelectedAltBuilding] = useState<AltBuilding>(currentAltBuilding ?? altBuildings[0]);
  const handleSwitchBuilding = useSetAltBuildingState(selectedAltBuilding, onClose);
  return (
    <>
      <AltBuildingModalSelect
        altBuildings={altBuildings}
        setSelectedAltBuilding={setSelectedAltBuilding}
        currentAltBuilding={currentAltBuilding}
        selectedAltBuilding={selectedAltBuilding}
      />
      <ButtonContainer>
        <SwitchBuildingButton
          onClick={handleSwitchBuilding}
          text={intl.formatMessage({ id: 'buttons.submit' })}
          disabled={!selectedAltBuilding}
          data-cy="submit-alt-building"
        />
        <CloseButton onClick={onClose} text={intl.formatMessage({ id: 'buttons.cancel' })} variant="closeButton" />
      </ButtonContainer>
    </>
  );
};
