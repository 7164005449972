import { Button } from '@hqo/react-components-library/dist/atoms/button';
import { NewVisitorLink } from 'components/shared/new-visitor-link';
import styled from 'styled-components';

export const StyledButton = styled(Button)`
  height: 100%;
  width: 100%;
  min-width: 160px;
  font-size: 16px;
  padding: 0;
  font-weight: bold;
  justify-content: center;
`;

export const NewVisitorLinkStyled = styled(NewVisitorLink)`
  height: 40px;
  width: 144px;
`;
