import { Redirect, generatePath } from 'react-router';

import { ERROR_PATH, ROOT_PATH } from 'shared/consts';
import { useViewParams } from 'hooks/use-view-params.hook';

export const VisitorRegistrationRoot = (): JSX.Element => {
  const params = useViewParams();

  if (!params.buildingUuid) {
    return <Redirect to={ERROR_PATH} />;
  }

  const targetUrl = `${generatePath(ROOT_PATH, { ownerId: params.buildingUuid, ownerType: 'building' })}`;

  return <Redirect to={targetUrl} />;
};
