import { useCallback, useMemo } from 'react';

import { OnErrorFn } from '@formatjs/intl/src/types';
import { flatten } from 'utils';
import messagesInEnglish from 'lang/en.json';
import messagesInSpanish from 'lang/es-ES.json';
import messagesInFrench from 'lang/fr-FR.json';
import messagesInCanadianFrench from 'lang/fr-CA.json';
import messagesInGerman from 'lang/de-DE.json';
import messagesInDutch from 'lang/nl-NL.json';
import messagesInItalian from 'lang/it-IT.json';

export const DEFAULT_LOCALE = 'en';

const messagesMap = new Map<string, Record<string, unknown>>([
  ['en', messagesInEnglish.visitorRegistration],
  ['es-es', messagesInSpanish.visitorRegistration],
  ['fr-fr', messagesInFrench.visitorRegistration],
  ['fr-ca', messagesInCanadianFrench.visitorRegistration],
  ['de-de', messagesInGerman.visitorRegistration],
  ['nl-nl', messagesInDutch.visitorRegistration],
  ['it-it', messagesInItalian.visitorRegistration],
]);

export const getAvailableLocale = (userLocale: string): [string, string] => {
  if (messagesMap.has(userLocale)) {
    return [userLocale, userLocale];
  }

  const [lang] = userLocale.split('-');

  if (messagesMap.has(lang)) {
    return [lang, userLocale];
  }

  return [DEFAULT_LOCALE, DEFAULT_LOCALE];
};

interface LocaleData {
  messages: Record<string, string>;
  locale: string;
}
export const useLocaleData = (userLocale: string): LocaleData => {
  return useMemo(() => {
    const [messagesKey, locale] = getAvailableLocale(userLocale);

    return {
      locale,
      messages: flatten(messagesMap.get(messagesKey)) as Record<string, string>,
    };
  }, [userLocale]);
};

export const useErrorHandler = (): OnErrorFn => {
  return useCallback((err) => {
    if (err.code === 'MISSING_TRANSLATION') {
      // eslint-disable-next-line no-console
      console.warn('Missing translation', err.message);
      return;
    }
    throw err;
  }, []);
};
