import { Building } from './types';
import { FailureActionPayload } from 'store/errors/types';
import { OwnerIdParam } from 'shared/types';
import { createAsyncAction } from 'typesafe-actions';

export const fetchBuilding = createAsyncAction(
  'building/FETCH_REQUEST',
  'building/FETCH_SUCCESS',
  'building/FETCH_FAILURE',
)<OwnerIdParam, Building, FailureActionPayload>();
