export const AddVisitorIcon = (): JSX.Element => (
  <svg width="50" height="40" viewBox="0 0 50 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M49.375 17.5H42.5V10.625C42.5 10.3125 42.1875 10 41.875 10H40.625C40.2344 10 40 10.3125 40
      10.625V17.5H33.125C32.7344 17.5 32.5 17.8125 32.5 18.125V19.375C32.5 19.7656 32.7344 20 33.125
      20H40V26.875C40 27.2656 40.2344 27.5 40.625 27.5H41.875C42.1875 27.5 42.5 27.2656 42.5
      26.875V20H49.375C49.6875 20 50 19.7656 50 19.375V18.125C50 17.8125 49.6875 17.5 49.375 17.5ZM24.4531
      22.5C22.1875 22.5 21.1719 23.75 17.5 23.75C13.75 23.75 12.7344 22.5 10.4688 22.5C4.6875 22.5 0
      27.2656 0 33.0469V36.25C0 38.3594 1.64062 40 3.75 40H31.25C33.2812 40 35 38.3594 35 36.25V33.0469C35
      27.2656 30.2344 22.5 24.4531 22.5ZM32.5 36.25C32.5 36.9531 31.875 37.5 31.25 37.5H3.75C3.04688 37.5
      2.5 36.9531 2.5 36.25V33.0469C2.5 28.5938 6.01562 25 10.4688 25C12.0312 25 13.5156 26.25 17.5
      26.25C21.4062 26.25 22.9688 25 24.4531 25C28.9062 25 32.5 28.5938 32.5 33.0469V36.25ZM17.5 20C22.9688
      20 27.5 15.5469 27.5 10C27.5 4.53125 22.9688 0 17.5 0C11.9531 0 7.5 4.53125 7.5 10C7.5 15.5469 11.9531
      20 17.5 20ZM17.5 2.5C21.5625 2.5 25 5.9375 25 10C25 14.1406 21.5625 17.5 17.5 17.5C13.3594 17.5 10 14.1406
      10 10C10 5.9375 13.3594 2.5 17.5 2.5Z"
      fill="currentColor"
    />
  </svg>
);
