import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { DefaultRedirect } from './default-redirect';
import { NewVisitorPage } from 'pages/new-visit-page';
import { VisitDetailsPage } from 'pages/visit-details-page';

export const CreateOnlyPage = (): JSX.Element => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/new`} component={NewVisitorPage} />
      <Route path={`${path}/:visitUiType(upcoming|past|all)/:visitId`} component={VisitDetailsPage} exact />
      <Route component={DefaultRedirect} />
    </Switch>
  );
};
