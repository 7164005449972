import { IntlShape, useIntl } from 'react-intl';
import { VisitorFrequencyEnum } from '../components/visit-form/types/visit-form-values';
import {
  useDateTimeFormatters,
  DateTimeFormatter,
} from '../shared/hooks/use-date-time-formatters/use-date-time-formatters.hook';

export const TIME_LOWER_CASE_FORMAT = 'h:mm a';

export interface RecurrenceData {
  frequency?: VisitorFrequencyEnum;
  endDate?: Date;
  count?: number;
}

enum RecurrenceVariant {
  RECURRENCE_UNITL = 'recurrence-until',
  RECURRENCE_COUNT = 'recurrence-count',
  RECURRENCE_NEVER = 'recurrence-never',
}

const getScheduleVariant = (schedule: RecurrenceData) => {
  if (schedule.count) {
    return RecurrenceVariant.RECURRENCE_COUNT;
  }
  if (!schedule.count && !schedule.endDate) {
    return RecurrenceVariant.RECURRENCE_NEVER;
  }
  return RecurrenceVariant.RECURRENCE_UNITL;
};

const getRecurringUntilText = (intl: IntlShape, schedule: RecurrenceData, formatDate: DateTimeFormatter) =>
  intl.formatMessage(
    { id: 'labels.recurrence_until' },
    {
      frequency: intl.formatMessage({ id: `labels.frequency_values.${schedule.frequency}` }),
      date: formatDate(schedule.endDate),
    },
  );

const getRecurringNeverText = (intl: IntlShape, schedule: RecurrenceData) =>
  intl.formatMessage(
    { id: 'labels.recurrence_never' },
    {
      frequency: intl.formatMessage({ id: `labels.frequency_values.${schedule.frequency}` }),
    },
  );

const getRecurringCountText = (intl: IntlShape, schedule: RecurrenceData) =>
  intl.formatMessage(
    { id: 'labels.recurrence_count' },
    {
      frequency: intl.formatMessage({ id: `labels.frequency_values.${schedule.frequency}` }),
      count: schedule.count,
    },
  );

const TEXT_BUILDERS = {
  [RecurrenceVariant.RECURRENCE_UNITL]: getRecurringUntilText,
  [RecurrenceVariant.RECURRENCE_COUNT]: getRecurringCountText,
  [RecurrenceVariant.RECURRENCE_NEVER]: getRecurringNeverText,
};

export const isRecurrenceValid = ({ frequency }: RecurrenceData) => !!frequency;

export const useScheduleData = (schedule: RecurrenceData) => {
  const intl = useIntl();
  const { formatDate } = useDateTimeFormatters({ dateFormat: { dateStyle: 'long' } });

  if (!isRecurrenceValid(schedule)) {
    return null;
  }
  const scheduleVariant = getScheduleVariant(schedule);

  return TEXT_BUILDERS[scheduleVariant](intl, schedule, formatDate);
};
