import styled, { css } from 'styled-components';

import { mediaMaxWidth } from '@hqo/react-components-library/dist/device';

export const VisitorNames = styled.div`
  font-size: 18px;
  font-weight: bold;

  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const VisitStatusPanel = styled.div`
  display: flex;
  flex-shrink: 0;
  min-width: 120px;
`;

export const EllipsisContainer = styled.div`
  display: flex;
`;

export const VisitStatus = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 14px;
`;

export const VisitorType = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.greys.sharedDarkGrey};
`;

export const VisitStatusLabel = styled(VisitStatus)`
  font-weight: bold;
  margin-right: 0.5em;
`;

export const VisitMainDataPanel = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  max-width: 368px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  margin-right: 2em;

  ${mediaMaxWidth(css`
    margin-right: 1em;
    max-width: none;
  `)}
`;

export const VisitDataPanel = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-around;
  overflow: hidden;
  text-overflow: ellipsis;

  ${mediaMaxWidth(css`
    flex-direction: column;
  `)}
`;
