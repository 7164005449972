import styled, { css } from 'styled-components';

import { NavLink } from 'react-router-dom';
import { mediaMaxWidth } from '@hqo/react-components-library/dist/device';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 60px;

  height: calc(100% - 108px);
  border-right: 1px solid ${({ theme }) => theme.colors.greys.adminGreyMediumAlt};

  ${mediaMaxWidth(css`
    flex-direction: row;
    padding: 0 16px;
    height: auto;
    border: 0 none;
  `)}
`;

export const Tab = styled(NavLink)`
  display: flex;
  align-items: center;
  height: 24px;
  font-size: 18px;
  padding-left: 12px;

  text-decoration: none;
  color: ${({ theme }) => theme.colors.greys.sharedDarkGrey};

  border-left: 2px solid ${({ theme }) => theme.colors.universal.transparent};

  margin-bottom: 32px;

  &.active {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: bold;
    border-color: ${({ theme }) => theme.colors.primary};
  }

  ${mediaMaxWidth(css`
    height: 30px;
    width: 82px;
    margin-right: 24px;
    margin-bottom: 0;
    padding: 0;
    font-size: 14px;
    justify-content: center;

    border-left: 0 none;
    border-bottom: 2px solid ${({ theme }) => theme.colors.universal.transparent};

    &.active {
      color: ${({ theme }) => theme.colors.fontColor};
      font-weight: 500;
    }
  `)}
`;
