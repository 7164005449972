import React, { useEffect } from 'react';
import { Router } from 'components/root/router';
import { SwitchAltBuildingModal } from 'components/alt-buildings-modal/alt-building-modal';
import { AppThemeProvider } from '@hqo/react-components-library/dist/tenant-web/elements/theme';
import { HqoTheme } from '@hqo/react-components-library/dist/hqo-theme-provider';
import { CustomFonts } from 'components/custom-fonts';
import { AppContentWrapperStyles } from 'components/root/app-content-wrapper/app-content-wrapper.styles';
import { useAltBuildings } from 'hooks/use-alt-buildings.hook';
import { useBooleanState } from '@hqo/react-components-library/dist/hooks';
import { useBuildingTheme } from 'hooks/use-building-theme.hook';
import { useTracking } from 'components/root/app/app.hooks';
import { useMiniappSdk } from 'hooks/use-miniapp-sdk.hook';
import { HEADER_BUTTON_MODE } from '@hqo/hqo-miniapp-client-sdk';
import { useLaunchDarklyIdentify } from 'hooks/use-launch-darkly-identify.hook';

export const AppContent: React.FC = (): JSX.Element => {
  useLaunchDarklyIdentify();
  const altBuildings = useAltBuildings();
  const miniappSdk = useMiniappSdk();
  const { value: visible, setFalse: handleClose } = useBooleanState(true);
  const theme = useBuildingTheme();
  useTracking();
  useEffect(() => {
    miniappSdk?.header.setHeader(undefined, HEADER_BUTTON_MODE.CLOSE);
  }, [miniappSdk]);

  if (altBuildings?.length && visible) {
    return (
      <>
        <AppContentWrapperStyles customThemeFonts={theme.fonts} />
        <CustomFonts />
        <AppThemeProvider theme={theme as HqoTheme}>
          <SwitchAltBuildingModal altBuildings={altBuildings} isVisible={visible} onClose={handleClose} />
        </AppThemeProvider>
      </>
    );
  }

  return <Router />;
};
