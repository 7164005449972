import { ArrivalDate, ArrivalTime, VisitDatePanel, CircularArrowsIcon, RecurrenceWrapper } from './date-panel.styles';

import { getDataLabel } from 'components/visitors-list/infinite-loading-list/visit-item/utils';
import { useDateTimeFormatters } from 'shared/hooks';
import { isRecurrenceValid, RecurrenceData } from 'hooks/use-schedule-data';
import { FormattedMessage } from 'react-intl';

interface DatePanelProps {
  arrivalDate?: Date;
  index: number;
  recurrenceData: RecurrenceData;
}

export const DatePanel = ({ arrivalDate, index, recurrenceData }: DatePanelProps): JSX.Element => {
  const { formatNumericDate, formatTime } = useDateTimeFormatters();
  const arrivalTimeString = formatTime(arrivalDate);
  const arrivalDateString = formatNumericDate(arrivalDate);
  const isRecurringVisit = isRecurrenceValid(recurrenceData);

  return (
    <VisitDatePanel>
      <ArrivalDate data-cy={getDataLabel(index, 'arrival-date')}>{arrivalDateString}</ArrivalDate>
      <ArrivalTime data-cy={getDataLabel(index, 'arrival-time')}>{arrivalTimeString}</ArrivalTime>
      {isRecurringVisit && (
        <RecurrenceWrapper>
          <CircularArrowsIcon size="sm" variant="primary" />
          <FormattedMessage id="labels.recurring" />
        </RecurrenceWrapper>
      )}
    </VisitDatePanel>
  );
};
