import { useDispatch, useSelector } from 'react-redux';

import { OwnerParams } from 'shared/types';
import { VisitUiType } from 'store/visits/types';
import { fetchVisits } from 'store/visits/actions';
import { getPaginatedVisitsState } from 'store/visits/selectors';
import { useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';

export const useIsItemLoadedCallback = (type: VisitUiType): ((index: number) => boolean) => {
  const { data } = useSelector(getPaginatedVisitsState(type));

  return useCallback((index: number): boolean => !!(data[index]?.data || data[index]?.error), [data]);
};

export const useItemsFetcher = (type: VisitUiType): ((startIndex: number, endIndex: number) => Promise<void>) => {
  const dispatch = useDispatch();
  const {
    params: { ownerId },
  } = useRouteMatch<OwnerParams>();

  return useCallback(
    async (startIndex: number, endIndex: number): Promise<void> => {
      dispatch(
        fetchVisits({
          type,
          buildingId: ownerId,
          limit: endIndex - startIndex + 1,
          offset: startIndex,
        }),
      );
    },
    [dispatch, type, ownerId],
  );
};
