import { StateType } from 'typesafe-actions';
import buildingReducer from 'store/building/reducer';
import { combineReducers } from 'redux';
import configReducer from 'store/config/reducer';
import escortsReducer from 'store/escorts/reducer';
import errorsReducer from 'store/errors/reducer';
import routerReducer from 'store/router/reducer';
import tenantBuildingReducer from 'store/tenant-building/reducer';
import themeReducer from 'store/theme/reducer';
import uiMetadataReducer from 'store/ui-metadata/reducer';
import userReducer from 'store/user/reducer';
import visitsReducer from 'store/visits/reducer';
import hostsReducer from 'store/hosts/reducer';
import altBuildingsReducer from 'store/alt-buildings/reducer';

const rootReducer = combineReducers({
  config: configReducer,
  router: routerReducer,
  user: userReducer,
  uiMetadata: uiMetadataReducer,
  visits: visitsReducer,
  building: buildingReducer,
  altBuildings: altBuildingsReducer,
  escorts: escortsReducer,
  tenantBuilding: tenantBuildingReducer,
  theme: themeReducer,
  errors: errorsReducer,
  hosts: hostsReducer,
});

export type RootState = StateType<typeof rootReducer>;

export default rootReducer;
