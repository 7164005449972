export const loginRequestAction = 'user/EXTERNAL_LOGIN_REQUEST';
export const loginResponseAction = 'user/EXTERNAL_LOGIN_RESPONSE';
export const loginFailureAction = 'user/EXTERNAL_LOGIN_FAILURE';

export const getUserRequestAction = 'user/GET_CURRENT_REQUEST';
export const getUserResponseAction = 'user/GET_CURRENT_RESPONSE';
export const getUserFailureAction = 'user/GET_CURRENT_FAILURE';

export const updateTokenAction = 'user/UPDATE_TOKEN';

export const setTokenRefreshingAction = 'user/SET_TOKEN_REFRESHING';

export const callActionAfterRefreshTokenAction = 'user/CALL_ACTION_AFTER_REFRESH_TOKEN';

export const callActionAfterRefreshTokenTimeoutAction = 'user/CALL_ACTION_AFTER_REFRESH_TOKEN_TIMEOUT';
