import { catchError, filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { Epic } from 'redux-observable';
import { RootAction } from 'store/actions';
import { RootDependencies } from 'store/dependencies';
import { RootState } from 'store/reducer';
import { fetchEscorts as fetchEscortsAction } from './actions';
import { isActionOf } from 'typesafe-actions';
import { of } from 'rxjs';
import { getErrorCode } from 'store/utils';

export const fetchEscorts: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(fetchEscortsAction.request)),
    withLatestFrom(state$),
    mergeMap(([{ payload }, state]) => {
      return apiClient(state)
        .fetchEscorts(payload)
        .pipe(
          map(({ response }) => fetchEscortsAction.success(response)),
          catchError((error: Error) => {
            return of(fetchEscortsAction.failure({ error, errorCode: getErrorCode(error) }));
          }),
        );
    }),
  );
