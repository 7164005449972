import { ROOT_PATH, VISITS_SUB_PATH } from 'shared/consts';
import { generatePath, useParams } from 'react-router';

import { InfiniteLoaderItemProps } from '@hqo/react-components-library/dist/molecules/infinite-loader';
import { OwnerParams } from 'shared/types';
import React from 'react';
import { VisitUiType } from 'store/visits/types';
import { LinkContent, WrapperLink } from './visit-item.styles';

interface VisitItemProps extends InfiniteLoaderItemProps {
  type: VisitUiType;
  children: React.ReactNode;
  visitId: string;
}

export const VisitDetailsLink = ({ style, type, children, visitId }: VisitItemProps): JSX.Element => {
  const params = useParams<OwnerParams>();

  const visitPage = generatePath(`${ROOT_PATH}/${VISITS_SUB_PATH}/:type/:visitId`, {
    ...params,
    type,
    visitId,
  });

  return (
    <WrapperLink style={style} data-cy="visit-card" to={visitPage}>
      <LinkContent>{children}</LinkContent>
    </WrapperLink>
  );
};
