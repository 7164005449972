import styled, { css } from 'styled-components';

import { ArrowRightChevron } from '@hqo/react-components-library/dist/icons';
import { Link } from 'react-router-dom';
import { mediaMaxWidth } from '@hqo/react-components-library/dist/device';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 5%;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.fontColor};
  line-height: 24px;

  ${mediaMaxWidth(css`
    justify-content: flex-start;
    align-items: start;
    padding-top: 16px;
  `)}
`;

export const LinkContent = styled.div`
  display: flex;
  align-items: start;
  width: 100%;
`;

export const WrapperLink = styled(Link)`
  display: flex;
  align-items: baseline;
  padding: 16px 25% 16px 8%;
  text-decoration: none;

  color: ${({ theme }) => theme.colors.fontColor};
  line-height: 24px;

  ${mediaMaxWidth(css`
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    margin-right: 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.greys.sharedMediumGrey};
  `)}
`;

export const CenteredChevron = styled(ArrowRightChevron)`
  align-self: center;
  margin-right: 3px;
  color: ${({ theme }) => theme.colors.greys.lightGrey2};
  flex-shrink: 0;
  display: none;

  ${mediaMaxWidth(css`
    display: initial;
  `)}
`;
