import { MiniappSdkContext } from 'components/miniapp-sdk-provider/miniapp-sdk.context';
import { useMiniappSdkClient } from 'hooks/use-miniapp-sdk.hook';
import React from 'react';
import { useSelector } from 'react-redux';
import { uiMetadata as uiMetadataSelector } from 'store/ui-metadata/selectors';

interface MiniappSdkProviderProps {
  children: React.ReactNode;
}

export const MiniappSdkProvider = ({ children }: MiniappSdkProviderProps): JSX.Element => {
  const uiMetadata = useSelector(uiMetadataSelector);
  const miniappSdkClient = useMiniappSdkClient(uiMetadata?.app_uuid);

  return <MiniappSdkContext.Provider value={miniappSdkClient}>{children}</MiniappSdkContext.Provider>;
};
