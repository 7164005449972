import * as buildingActions from 'store/building/actions';
import * as altBuildingActions from 'store/alt-buildings/actions';
import * as configActions from 'store/config/actions';
import * as escortsActions from 'store/escorts/actions';
import * as notificationsActions from 'store/errors/actions';
import * as tenantBuildingActions from 'store/tenant-building/actions';
import * as themeActions from 'store/theme/actions';
import * as uiMetadataActions from 'store/ui-metadata/actions';
import * as userActions from 'store/user/actions';
import * as visitsActions from 'store/visits/actions';
import * as hostsActions from 'store/hosts/actions';

import { CallHistoryMethodAction, routerActions } from 'connected-react-router';
import { LocationState, Path } from 'history';

import { ActionType } from 'typesafe-actions';

// TODO: remove RouterActions custom types after https://github.com/supasate/connected-react-router/issues/286 fixed
type Push = (path: Path, state?: LocationState) => CallHistoryMethodAction<[Path, LocationState?]>;
type Replace = (path: Path, state?: LocationState) => CallHistoryMethodAction<[Path, LocationState?]>;

interface RouterActions {
  push: Push;
  replace: Replace;
  // go: Go; etc.
}

const actions = {
  router: routerActions as RouterActions,
  user: userActions,
  uiMetadata: uiMetadataActions,
  visits: visitsActions,
  building: buildingActions,
  altBuilding: altBuildingActions,
  escorts: escortsActions,
  tenantBuilding: tenantBuildingActions,
  theme: themeActions,
  config: configActions,
  notifications: notificationsActions,
  hosts: hostsActions,
};

export type RootAction = ActionType<typeof actions>;
export default actions;
